<footer class="bg-gray-200 py-8">
  <div class="container">
    <div class="flex flex-wrap md:gap-4 lg:gap-0 py-4 mb-6">
      <div class="w-full md:w-full lg:w-1/3 flex flex-col gap-4 mb-6">
        <h6>Categorias</h6>
        <div class="flex flex-wrap">
          <div class="w-full">
            <!-- list -->
            <ul class="flex flex-col gap-2">
              <li *ngFor="let category of categories">
                <a href="#!" class="inline-block hover:text-orange-600">{{
                  category.name
                }}</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="w-full md:w-full lg:w-2/3">
        <div class="flex flex-wrap">
          <div class="w-1/2 sm:w-1/2 md:w-1/4 flex flex-col gap-4 mb-6">
            <h6>Conócenos</h6>
            <!-- list -->
            <ul class="flex flex-col gap-2">
              <li>
                <a
                  routerLink="/conocenos"
                  class="inline-block hover:text-orange-600"
                  >Empresa</a
                >
              </li>
              <li>
                <a
                  routerLink="/about"
                  class="inline-block hover:text-orange-600"
                  >Acerca de</a
                >
              </li>
              <li><a href="#!" class="inline-block">Blog</a></li>
              <li>
                <a
                  routerLink="/atencion-a-clientes"
                  class="inline-block hover:text-orange-600"
                  >Centro de ayuda</a
                >
              </li>
              <li>
                <a
                  routerLink="/valores"
                  class="inline-block hover:text-orange-600"
                  >Nuestros valores</a
                >
              </li>
            </ul>
          </div>
          <div class="w-1/2 sm:w-1/2 md:w-1/4 flex flex-col gap-4 mb-6">
            <h6>Para los Clientes</h6>
            <ul class="flex flex-col gap-2">
              <!-- list -->
              <li>
                <a
                  routerLink="/pagos"
                  class="inline-block hover:text-orange-600"
                  >Pagos</a
                >
              </li>
              <li>
                <a
                  routerLink="/envios"
                  class="inline-block hover:text-orange-600"
                  >Envíos</a
                >
              </li>
              <li>
                <a href="#!" class="inline-block hover:text-orange-600"
                  >Devoluciones</a
                >
              </li>
              <li>
                <a href="#!" class="inline-block hover:text-orange-600">FAQ</a>
              </li>
              <li>
                <a href="./shop-checkout.html" class="inline-block"
                  >Proceso de pago</a
                >
              </li>
            </ul>
          </div>
          <div class="w-1/2 sm:w-1/2 md:w-1/4 flex flex-col gap-4">
            <h6>Become a Shopper</h6>
            <ul class="flex flex-col gap-2">
              <!-- list -->
              <li>
                <a href="#!" class="inline-block hover:text-orange-600"
                  >Shopper Opportunities</a
                >
              </li>
              <li>
                <a href="#!" class="inline-block hover:text-orange-600"
                  >Become a Shopper</a
                >
              </li>
              <li>
                <a href="#!" class="inline-block hover:text-orange-600"
                  >Earnings</a
                >
              </li>
              <li>
                <a href="#!" class="inline-block hover:text-orange-600"
                  >Ideas & Guides</a
                >
              </li>
              <li>
                <a href="#!" class="inline-block hover:text-orange-600"
                  >New Retailers</a
                >
              </li>
            </ul>
          </div>
          <div class="w-1/2 sm:w-1/2 md:w-1/4 flex flex-col gap-4">
            <h6>Viammex programs</h6>
            <ul class="flex flex-col gap-2">
              <!-- list -->
              <li>
                <a href="#!" class="inline-block hover:text-orange-600"
                  >Freshcart programs</a
                >
              </li>
              <li>
                <a href="#!" class="inline-block hover:text-orange-600"
                  >Gift Cards</a
                >
              </li>
              <li>
                <a href="#!" class="inline-block hover:text-orange-600"
                  >Promos & Coupons</a
                >
              </li>
              <li>
                <a href="#!" class="inline-block hover:text-orange-600"
                  >Freshcart Ads</a
                >
              </li>
              <li>
                <a href="#!" class="inline-block hover:text-orange-600"
                  >Careers</a
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="border-t py-4 border-gray-300">
      <div
        class="gap-y-4 flex flex-wrap items-center justify-center lg:justify-start"
      >
        <div class="lg:w-2/5 lg:text-left text-center">
          <div class="flex md:flex-row flex-col gap-3 md:gap-6 items-center">
            <div class="text-gray-900">Socios de pago</div>
            <ul class="flex items-center flex-row gap-4">
              <li>
                <a href="#!"
                  ><img
                    src="./assets/images/payment/stripe-seeklogo.svg"
                    alt="stripe"
                /></a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="border-t py-4 border-gray-300">
      <div class="flex flex-col md:flex-row items-center gap-3">
        <div class="md:w-1/2">
          <span class="text-sm text-gray-500">
            ©
            <span id="copyright">
              <script>
                document
                  .getElementById("copyright")
                  .appendChild(
                    document.createTextNode(new Date().getFullYear()),
                  );
              </script>
            </span>
            Viammex. Todos los derechos reservados.
          </span>
        </div>
        <div class="md:w-1/2 flex md:justify-end items-center">
          <div class="flex flex-row gap-5 items-center">
            <div class="text-gray-500">Siguenos en</div>
            <ul class="flex items-center justify-end text-sm gap-1">
              <li>
                <a
                  href="#!"
                  class="inline-flex justify-center items-center align-middle text-center select-none border font-normal whitespace-no-wrap rounded leading-normal no-underline h-8 w-8 border-gray-300 hover:border-orange-600 hover:text-orange-600 transition ease-in-out"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="icon icon-tabler icon-tabler-brand-facebook"
                    width="16"
                    height="16"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path
                      d="M7 10v4h3v7h4v-7h3l1 -4h-4v-2a1 1 0 0 1 1 -1h3v-4h-3a5 5 0 0 0 -5 5v2h-3"
                    />
                  </svg>
                </a>
              </li>
              <li>
                <a
                  href="#!"
                  class="inline-flex justify-center items-center align-middle text-center select-none border font-normal whitespace-no-wrap rounded leading-normal no-underline h-8 w-8 border-gray-300 hover:border-orange-600 hover:text-orange-600 transition ease-in-out"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="icon icon-tabler icon-tabler-brand-x"
                    width="16"
                    height="16"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M4 4l11.733 16h4.267l-11.733 -16z" />
                    <path d="M4 20l6.768 -6.768m2.46 -2.46l6.772 -6.772" />
                  </svg>
                </a>
              </li>
              <li>
                <a
                  href="#!"
                  class="inline-flex justify-center items-center align-middle text-center select-none border font-normal whitespace-no-wrap rounded leading-normal no-underline h-8 w-8 border-gray-300 hover:border-orange-600 hover:text-orange-600 transition ease-in-out"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="icon icon-tabler icon-tabler-brand-instagram"
                    width="16"
                    height="16"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path
                      d="M4 4m0 4a4 4 0 0 1 4 -4h8a4 4 0 0 1 4 4v8a4 4 0 0 1 -4 4h-8a4 4 0 0 1 -4 -4z"
                    />
                    <path d="M12 12m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0" />
                    <path d="M16.5 7.5l0 .01" />
                  </svg>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</footer>
