<section class="my-10">
  <!-- container -->
  <div class="container">
    <div class="flex flex-wrap justify-center">
      <div class="lg:mx-1/6 xl:w-4/5 w-full">
        <div class="flex gap-6 flex-wrap md:flex-nowrap items-center mb-8">
          <div class="md:w-1/2">
            <!-- text -->
            <div class="lg:mx-20 flex flex-col gap-4">
              <h1 class="text-2xl leading-tight">Logística</h1>
              <p class="text-md text-justify">
                En el tema de logística tenemos un socio aliado para hacer de tu
                experiencia más sencilla, rápida y confiable. Trabajamos con
                envia.com donde se pueden utilizar hasta 12 paqueterías
                diferentes para cotizar el mejor precio, tiempo y servicio.
              </p>
              <p class="text-md text-justify">
                Una vez cotizado el pedido, podrás cotizar por tu parte el envío
                y decidir si se lo incluyes al cliente o si quieres absorber el
                gasto para dar un mejor servicio a tus clientes, es tu decisión.
                Ese mismo pedido con envio lo mandas directo al cliente para que
                decidan si aceptan o no, con el pago realizado.
              </p>
              <p class="text-md text-justify">
                Ese pago es retenido hasta que se envíe el producto, donde
                recibirán un porcentaje de la venta, dejando pendiente otro
                porcentaje que se liberará cuando el cliente acepte el producto
                y las condiciones en las que viene al igual que cuando la
                paquetería nos avise que el producto ha sido entregado. 
              </p>
            </div>
          </div>

          <div class="md:w-1/2 w-full">
            <!-- img -->
            <img
              src="{{ url }}10.jpg"
              alt=""
              class="max-w-full w-full h-auto rounded-lg"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
