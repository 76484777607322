import { Component, OnInit } from '@angular/core';
import { ShopService } from 'src/app/shop/shop.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  currentYear = new Date().getFullYear();
  categories: any;

  constructor(private shopService: ShopService) {}

  ngOnInit(): void {
    this.getCategories();
  }

  getCategories() {
    this.shopService.getProductCategory1().subscribe({
      next: (response) => {
        this.categories = response;
      },
      error: (error) => console.log(error),
    });
  }
}
