<ngx-spinner type="timer">
  <h3>Loading...</h3>
</ngx-spinner>

<app-nav-bar></app-nav-bar>

<router-outlet>
  <p-toast key="toast"></p-toast>
  <p-messages key="msg"></p-messages>
</router-outlet>

<!-- footer -->
<app-footer></app-footer>

<!-- Footer container -->
