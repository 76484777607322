import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-envios',
  templateUrl: './envios.component.html',
  styleUrl: './envios.component.scss',
})
export class EnviosComponent {
  url = environment.contentUrl + 'images/pages/';
}
