<main>
  <!-- section -->

  <section class="my-10">
    <!-- container -->
    <div class="container">
      <div class="flex flex-wrap justify-center">
        <div class="lg:mx-1/6 xl:w-4/5 w-full">
          <div class="flex gap-6 flex-wrap md:flex-nowrap items-center mb-8">
            <div class="md:w-full">
              <div class="accordion accordion-flush">
                <!-- accordion item -->
                <div class="border-b border-gray-300 py-4">
                  <div class="flex justify-between items-center">
                    <!-- heading one -->
                    <a
                      href="#"
                      class="flex flex-row gap-2 items-center justify-between w-full text-gray-800 text-md font-semibold collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseOne"
                      aria-expanded="true"
                      aria-controls="flush-collapseOne"
                    >
                      <div class="lg:mx-20 flex flex-col gap-4 mt-4">
                        <h1 class="text-lg leading-tight">Valores</h1>
                      </div>
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="icon icon-tabler icons-tabler-outline icon-tabler-chevron-down"
                        >
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <path d="M6 9l6 6l6 -6" />
                        </svg>
                      </span>
                    </a>
                    <!-- collapse -->
                  </div>
                  <div
                    id="flush-collapseOne"
                    class="accordion-collapse collapse show"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div class="lg:mx-20 flex flex-col gap-4 mt-4">
                      <p class="text-md text-justify">
                        En <strong>Viammex</strong> , creemos que el comercio no
                        solo es una transacción, sino una forma de construir
                        puentes que conecten a empresas, comunidades y
                        consumidores con un propósito mayor. Nuestros valores
                        guían cada decisión que tomamos y cada servicio que
                        ofrecemos:
                      </p>

                      <ol>
                        <li class="text-md text-justify mt-2">
                          <strong>1. Autenticidad:</strong> Celebramos y
                          promovemos lo mejor del talento mexicano, asegurando
                          que cada producto ofrecido en nuestra plataforma
                          represente calidad, esfuerzo y tradición.
                        </li>
                        <li class="text-md text-justify mt-2">
                          <strong>2. Confianza:</strong> Valoramos la
                          transparencia y la ética en todas nuestras
                          interacciones. Queremos que tanto vendedores como
                          compradores sientan que Viammex es un espacio seguro y
                          profesional para crecer juntos.
                        </li>
                        <li class="text-md text-justify mt-2">
                          <strong>3. Innovación:</strong> Nos adaptamos
                          constantemente a las necesidades del mercado,
                          ofreciendo herramientas modernas que simplifican el
                          comercio y fortalecen las relaciones entre las partes
                          involucradas.
                        </li>
                        <li class="text-md text-justify mt-2">
                          <strong>4. Sustentabilidad:</strong> Estamos
                          comprometidos con prácticas que apoyen el desarrollo
                          económico de las comunidades locales, mientras
                          cuidamos el impacto ambiental de nuestras operaciones.
                        </li>
                        <li class="text-md text-justify mt-2">
                          <strong>5. Colaboración:</strong> Creemos que el éxito
                          es compartido. Por eso, trabajamos de la mano con
                          vendedores y compradores para garantizar una
                          experiencia enriquecedora y mutuamente beneficiosa.
                        </li>
                      </ol>
                    </div>
                  </div>
                </div>
                <div class="border-b border-gray-300 py-4">
                  <div class="flex justify-between items-center">
                    <!-- heading one -->
                    <a
                      href="#"
                      class="flex flex-row gap-2 items-center justify-between w-full text-gray-800 text-md font-semibold collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseTwo"
                      aria-expanded="false"
                      aria-controls="flush-collapseTwo"
                    >
                      <div class="lg:mx-20 flex flex-col gap-4 mt-4">
                        <h1 class="text-lg leading-tight">Misión Social</h1>
                      </div>
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="icon icon-tabler icons-tabler-outline icon-tabler-chevron-down"
                        >
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <path d="M6 9l6 6l6 -6" />
                        </svg>
                      </span>
                    </a>
                    <!-- collapse -->
                  </div>
                  <div
                    id="flush-collapseTwo"
                    class="accordion-collapse collapse"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div class="lg:mx-20 flex flex-col gap-4 mt-4">
                      <p class="text-md text-justify">
                        En un país lleno de creatividad, tradición y calidad,
                        nuestro propósito es simple pero poderoso:
                        <strong
                          >impulsar a las empresas mexicanas hacia nuevas
                          alturas.</strong
                        >
                      </p>

                      <p class="text-md text-justify">
                        <strong>Viammex</strong> busca ser un aliado estratégico
                        para pequeñas y medianas empresas, artesanos y
                        emprendedores que desean llevar sus productos a mercados
                        más amplios. Nuestro enfoque social está diseñado para:
                      </p>

                      <li class="text-md text-justify">
                        <strong>Fortalecer el comercio local:</strong> Apoyamos
                        a empresas mexicanas para que compitan en igualdad de
                        condiciones con gigantes globales, manteniendo la
                        riqueza cultural y económica dentro de nuestras
                        fronteras.
                      </li>
                      <li class="text-md text-justify">
                        <strong>Reducir barreras:</strong> Brindamos las
                        herramientas necesarias para que cualquier negocio, sin
                        importar su tamaño, pueda ofrecer sus productos en línea
                        de manera profesional y accesible.
                      </li>
                      <li class="text-md text-justify">
                        <strong>Conectar comunidades:</strong> Más allá de una
                        transacción, buscamos construir relaciones entre
                        productores y consumidores que compartan el mismo amor
                        por México y sus productos.
                      </li>
                      <li class="text-md text-justify">
                        <strong>Fomentar el crecimiento económico:</strong> Cada
                        venta realizada en nuestra plataforma representa más
                        empleos, más oportunidades y un impacto positivo en
                        nuestra economía local.
                      </li>
                      <li class="text-md text-justify">
                        <strong>Apoyar la inclusión tecnológica:</strong>
                        Queremos que cualquier negocio, incluso aquellos sin
                        experiencia en comercio digital, pueda encontrar en
                        Viammex un punto de partida accesible y eficiente.
                      </li>
                    </div>
                  </div>
                </div>
                <div class="border-b border-gray-300 py-4">
                  <div class="flex justify-between items-center">
                    <!-- heading one -->
                    <a
                      href="#"
                      class="flex flex-row gap-2 items-center justify-between w-full text-gray-800 text-md font-semibold collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseThree"
                      aria-expanded="false"
                      aria-controls="flush-collapseThree"
                    >
                      <div class="lg:mx-20 flex flex-col gap-4 mt-4">
                        <h1 class="text-lg leading-tight">Compromiso</h1>
                      </div>
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="icon icon-tabler icons-tabler-outline icon-tabler-chevron-down"
                        >
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <path d="M6 9l6 6l6 -6" />
                        </svg>
                      </span>
                    </a>
                    <!-- collapse -->
                  </div>
                  <div
                    id="flush-collapseThree"
                    class="accordion-collapse collapse"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div class="mt-4">
                      <div class="lg:mx-20 flex flex-col gap-4 mt-4">
                        <p class="text-md text-justify">
                          En Viammex, sabemos que juntos podemos construir un
                          México más fuerte, dinámico y competitivo. Te
                          invitamos a unirte a nuestra misión y ser parte del
                          cambio positivo que queremos ver en el comercio
                          nacional.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</main>
