<section class="my-10">
  <!-- container -->
  <div class="container">
    <div class="flex flex-wrap justify-center">
      <div class="lg:mx-1/6 xl:w-4/5 w-full">
        <div class="flex gap-6 flex-wrap md:flex-nowrap items-center mb-8">
          <div class="md:w-1/2">
            <!-- text -->
            <div class="lg:mx-20 flex flex-col gap-4">
              <h1 class="text-2xl leading-tight">Transacción Segura:</h1>
              <p class="text-md text-justify">
                Todas las transacciones hechas en Viammex son monitoreadas para
                cumplir con los estándares de la página. Recuerda que los
                proveedores son verificados con diferentes parámetros que nos
                permiten comprobar su veracidad y seriedad a la hora de vender
                en nuestra página.  
              </p>
              <p class="text-md text-justify">
                Nuestro Sello de Transacción Segura se tiene a partir de que se
                llega a un acuerdo con un proveedor de nuestra plataforma.
              </p>
              <p class="text-md text-justify">
                El pago se hace a través de nuestra plataforma para que podamos
                ayudar en caso de cualquier suceso negativo para cualquiera de
                las partes.
              </p>
              <p class="text-md text-justify">
                La transacción queda en Escrow hasta que se complete y se
                compruebe la conformidad de las dos partes, en ese momento se
                libera el monto a la parte vendedora.
              </p>
            </div>
          </div>

          <div class="md:w-1/2 w-full">
            <!-- img -->
            <img
              src="{{ url }}5.jpg"
              alt=""
              class="max-w-full w-full h-auto rounded-lg"
            />
          </div>
        </div>

        <div class="flex flex-wrap gap-6 mb-8">
          <div class="w-full">
            <!-- heading -->
            <h2 class="text-xl">Seguridad y Transparencia en Pagos</h2>
            <p class="text-md mt-4">
              En Viammex, valoramos la confianza en cada transacción. Por ello,
              hemos desarrollado un sistema de pagos que prioriza la seguridad
              para compradores y vendedores:
            </p>
          </div>
          <div class="grid md:grid-cols-2 gap-6">
            <!-- card -->
            <div class="card bg-gray-100 border-0">
              <!-- card body -->
              <div class="card-body p-8 flex flex-col gap-5">
                <div class="flex flex-col gap-2">
                  <h3 class="text-lg">Retención de Fondos</h3>
                  <p class="text-md text-justify">
                    Cuando un cliente realiza un pago, este es retenido por
                    nuestra plataforma hasta que la transacción se complete de
                    manera satisfactoria. Este proceso garantiza que ambas
                    partes cumplan con sus compromisos y se brinda protección a
                    compradores y vendedores en todo momento.
                  </p>
                </div>
              </div>
            </div>

            <!-- card -->
            <div class="card bg-gray-100 border-0">
              <!-- card body -->
              <div class="card-body p-8 flex flex-col gap-5">
                <div class="flex flex-col gap-2">
                  <h3 class="text-lg">
                    Liberación del Pago al Finalizar la Transacción:
                  </h3>
                  <p class="text-md text-justify">
                    Una vez que el comprador confirma la recepción del producto
                    y está satisfecho con la operación, los fondos se liberan al
                    vendedor. Este sistema asegura una experiencia comercial
                    confiable y transparente para todos.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div class="w-full">
            <p class="text-md">
              Con Viammex, puedes comprar y vender con tranquilidad, sabiendo
              que tus fondos están protegidos en todo momento.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="my-10">
  <!-- container -->
  <div class="container">
    <div class="flex flex-wrap justify-center">
      <div class="lg:mx-1/6 xl:w-4/5 w-full">
        <div class="flex gap-6 flex-wrap md:flex-nowrap items-center mb-8">
          <div class="md:w-full">
            <!-- text -->
            <h1 class="text-2xl leading-tight">
              Suscripciones para Vendedores
            </h1>
            <p class="text-md text-justify mt-4">
              En Viammex, sabemos que ser parte de una plataforma profesional y
              de alcance nacional es fundamental para el crecimiento de tu
              negocio. Por eso, nuestras suscripciones están diseñadas para
              ofrecerte las herramientas y el soporte que necesitas para
              destacar en el mercado.
            </p>
          </div>
        </div>

        <div class="flex flex-wrap gap-6 mb-8">
          <div class="w-full">
            <!-- heading -->
            <h2 class="text-xl">¿Qué incluye tu suscripción?</h2>

            <div class="grid md:grid-cols-4 gap-6">
              <!-- card -->
              <div class="card bg-gray-100 border-0">
                <!-- card body -->
                <div class="card-body p-8 flex flex-col gap-5">
                  <div class="flex flex-col gap-2">
                    <h3 class="text-md">1. Acceso a la plataforma:</h3>
                    <p class="text-md text-justify">
                      Cuando un cliente realiza un pago, este es retenido por Tu
                      negocio estará en una página web moderna y profesional que
                      conecta exclusivamente a empresas mexicanas con
                      compradores dentro del país.
                    </p>
                  </div>
                </div>
              </div>

              <!-- card -->
              <div class="card bg-gray-100 border-0">
                <!-- card body -->
                <div class="card-body p-8 flex flex-col gap-5">
                  <div class="flex flex-col gap-2">
                    <h3 class="text-md">2. Atención personalizada:</h3>
                    <p class="text-md text-justify">
                      Nuestro equipo de atención a cliente está siempre
                      disponible para resolver tus dudas y brindarte soporte en
                      cada paso de tu experiencia en Viammex.
                    </p>
                  </div>
                </div>
              </div>

              <div class="card bg-gray-100 border-0">
                <!-- card body -->
                <div class="card-body p-8 flex flex-col gap-5">
                  <div class="flex flex-col gap-2">
                    <h3 class="text-md">3. Mayor alcance:</h3>
                    <p class="text-md text-justify">
                      Al registrarte, tu negocio estará en una plataforma que te
                      ayuda a llegar a más clientes, algo que difícilmente
                      lograrías con un negocio independiente.
                    </p>
                  </div>
                </div>
              </div>

              <div class="card bg-gray-100 border-0">
                <!-- card body -->
                <div class="card-body p-8 flex flex-col gap-5">
                  <div class="flex flex-col gap-2">
                    <h3 class="text-md">4. Gestión simplificada:</h3>
                    <p class="text-md text-justify">
                      Con nuestra plataforma, puedes gestionar tus productos,
                      pedidos y ventas de manera centralizada y fácil de usar,
                      ahorrando tiempo y recursos.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="flex gap-6 flex-wrap md:flex-nowrap items-center mb-8">
          <div class="md:w-1/2">
            <!-- text -->
            <div class="lg:mx-20 flex flex-col gap-4">
              <h1 class="text-xl leading-tight">Por qué elegir Viammex:</h1>
              <p class="text-md text-justify">
                Tu suscripción no solo es una inversión en tecnología, sino
                también en el crecimiento y la profesionalización de tu negocio.
                Te conectamos con un ecosistema comercial diseñado para el
                éxito.
              </p>
            </div>
          </div>

          <div class="md:w-1/2 w-full flex flex-col items-center">
            <img
              src="./assets/images/svg-graphics/signup-g.svg"
              alt=""
              class="max-w-full w-full h-auto rounded-lg"
            />
            <button
              routerLink="/account/register/provider"
              class="btn inline-flex items-center gap-x-2 bg-orange-600 text-white border-orange-600 disabled:opacity-50 disabled:pointer-events-none hover:text-white hover:bg-orange-700 hover:border-orange-700 active:bg-orange-700 active:border-orange-700 focus:outline-none focus:ring-4 focus:ring-orange-300"
            >
              Registrarse
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
