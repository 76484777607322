import {
  AfterViewChecked,
  AfterViewInit,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { ZoomService } from '../../services/zoom.service';
import { TinySliderService } from '../../services/tiny-slider.service';

@Component({
  selector: 'app-product-modal',
  templateUrl: './product-modal.component.html',
  styleUrls: ['./product-modal.component.scss'],
})
export class ProductModalComponent implements OnInit {
  @Input() product: any;

  constructor(
    public zoomService: ZoomService,
    public tinySliderService: TinySliderService,
  ) {} // Inyecta el servicio ZoomService

  ngOnInit() {}

  getRatingArray(rating: number): number[] {
    // Cambiamos any[] a number[]
    const fullStars = Math.floor(rating);
    const hasHalfStar = rating - fullStars >= 0.5;
    const ratingArray = Array(fullStars).fill(1); // Rellenamos con 1 (o cualquier número)
    if (hasHalfStar) {
      ratingArray.push(0.5); // Agregamos 0.5 para la media estrella
    }
    return ratingArray;
  }
}
