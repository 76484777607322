import { Component, OnInit } from '@angular/core';
import { AccountService } from './account/account.service';
import { BasketService } from './basket/basket.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ShopService } from './shop/shop.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'Skinet';
  categories: any;

  constructor(
    private basketService: BasketService,
    private accountService: AccountService,
    private shopService: ShopService,
    private route: Router,
  ) {
    const url = route.routerState;
  }

  ngOnInit(): void {
    this.loadBasket();
    this.loadCurrentUser();
    this.getCategories();
  }

  loadBasket() {
    const basketId = localStorage.getItem('basket_id');
    if (basketId) this.basketService.getBasket(basketId);
  }

  loadCurrentUser() {
    const token = localStorage.getItem('token');
    this.accountService.loadCurrentUser(token).subscribe();
  }

  getCategories() {
    this.shopService.getProductCategory1().subscribe({
      next: (response) => {
        this.categories = response;
      },
      error: (error) => console.log(error),
    });
  }
}
