import { Injectable, ElementRef } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class CountdownService {
  constructor() {}

  initializeCountdown(element: HTMLElement) {
    const finalDate = element.getAttribute("data-countdown");
    if (!finalDate) {
      return;
    }

    const updateCountdown = () => {
      const now = new Date().getTime();
      const distance = new Date(finalDate).getTime() - now;

      if (distance <= 0) {
        clearInterval(interval);
        element.innerHTML = "Countdown expired";
        return;
      }

      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);

      element.innerHTML = `
        <span class="countdown-section">
          <span class="countdown-amount hover-up">${days}</span>
          <span class="countdown-period"> days </span>
        </span>
        <span class="countdown-section">
          <span class="countdown-amount hover-up">${hours}</span>
          <span class="countdown-period"> hours </span>
        </span>
        <span class="countdown-section">
          <span class="countdown-amount hover-up">${minutes}</span>
          <span class="countdown-period"> mins </span>
        </span>
        <span class="countdown-section">
          <span class="countdown-amount hover-up">${seconds}</span>
          <span class="countdown-period"> sec </span>
        </span>
      `;
    };

    updateCountdown();
    const interval = setInterval(updateCountdown, 1000);
  }
}
