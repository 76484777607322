import { Injectable, ElementRef } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class ZoomService {
  constructor() {}

  zoom2(event: any) {
    const target = event.currentTarget as HTMLElement;
    const offsetX = event.offsetX || event?.touches[0]?.pageX;
    const offsetY = event.offsetY || event?.touches[0]?.pageY;
    const x = (offsetX / target.offsetWidth) * 100;
    const y = (offsetY / target.offsetHeight) * 100;
    target.style.backgroundPosition = `${x}% ${y}%`;
  }

  zoom(event: any) {
    const target = event.currentTarget as HTMLElement;
    const offsetX =
      event.offsetX ||
      (event.touches && event.touches[0] ? event.touches[0].pageX : 0);
    const offsetY =
      event.offsetY ||
      (event.touches && event.touches[0] ? event.touches[0].pageY : 0);
    const x = (offsetX / target.offsetWidth) * 100;
    const y = (offsetY / target.offsetHeight) * 100;
    target.style.backgroundPosition = `${x}% ${y}%`;
  }
}
