import { Injectable, ElementRef } from "@angular/core";
import { tns } from "tiny-slider"; // Asegúrate de tener instalado tiny-slider

@Injectable({
  providedIn: "root",
})
export class TinySliderService {
  constructor() {}

  initializeTinySliders() {
    // Modal product
    const productModal = document.getElementById("productModal");
    if (productModal) {
      const modalSlider = tns({
        container: productModal,
        items: 1,
        startIndex: 0,
        navContainer: "#productModalThumbnails",
        navAsThumbnails: true,
        autoplay: false,
        autoplayTimeout: 1500,
        swipeAngle: false,
        speed: 1500,
        controls: false,
        autoplayButtonOutput: false,
        loop: false,
      });
    }

    // Slider for product
    const productSliderContainer = document.getElementById("product");
    if (productSliderContainer) {
      const productSlider = tns({
        container: productSliderContainer,
        items: 1,
        startIndex: 0,
        navContainer: "#productThumbnails",
        navAsThumbnails: true,
        autoplay: false,
        autoplayTimeout: 1500,
        swipeAngle: false,
        speed: 1500,
        controls: false,
        autoplayButtonOutput: false,
      });
    }
  }
}
