import { Component } from '@angular/core';

@Component({
  selector: 'app-valores',
  templateUrl: './valores.component.html',
  styleUrl: './valores.component.scss'
})
export class ValoresComponent {

}
