<div class="container mx-auto px-4 py-4">
  <div class="vendor-info">
    <section class="grid md:grid-cols-2 sm:grid-cols-1">
      <div class="flex align-items-center gap-3 p-3">
        <!--
        <p-avatar
          [image]="getCampoValor('avatar')"
          size="xlarge"
          shape="circle"
          *ngIf="mostrarCampo('avatar')"
        ></p-avatar>-->
        <p-avatar
          [image]="vendor.avatar"
          size="xlarge"
          shape="circle"
        ></p-avatar>
        <div>
          <h1
            class="text-2xl font-bold mb-2"
            *ngIf="mostrarCampo('nombreTienda')"
          >
            {{ getCampoValor("nombreTienda") }}
          </h1>
          <div class="flex align-items-center gap-2 mb-2">
            <i class="pi pi-star-fill text-yellow-500"></i>
            <span class="font-semibold">4.8</span>
            <span class="text-500">(120 ventas)</span>
          </div>
          <div
            class="flex align-items-center gap-2 text-500"
            *ngIf="mostrarCampo('ubicacion')"
          >
            <i class="pi pi-map-marker"></i>
            <span>{{ getCampoValor("ubicacion") }}</span>
          </div>
          <p class="text-justify" *ngIf="mostrarCampo('descripcion')">
            {{ getCampoValor("descripcion") }}
          </p>
        </div>
      </div>

      <div class="flex md:justify-end justify-center items-center p-3">
        <p-button label="Contactar vendedor" icon="pi pi-send"></p-button>
      </div>
    </section>
  </div>

  <h2 class="text-2xl font-semibold my-4">Productos de {{ vendor.name }}</h2>

  <div class="tw-grid tw-grid-cols-2 sm:tw-grid-cols-2 md:tw-grid-cols-5 gap-4">
    <ng-container *ngFor="let product of products">
      <div class="product-card" routerLink="/shop/{{ product.id }}">
        <div class="card-normal">
          <img [src]="product.pictureUrl" [alt]="product.name" />
          <h3>{{ product.name }}</h3>
          <p>{{ product.price | currency }}</p>
        </div>
      </div>
    </ng-container>
  </div>
</div>
