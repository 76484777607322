<main>
  <app-product-modal [product]="selectedProduct"></app-product-modal>

  <section class="mt-8">
    <div class="container">
      <div
        class="swiper-container swiper"
        id="swiper-1"
        data-pagination-type=""
        data-speed="400"
        data-space-between="100"
        data-pagination="true"
        data-navigation="false"
        data-autoplay="true"
        data-autoplay-delay="3000"
        data-effect="fade"
        data-breakpoints='{"480": {"slidesPerView": 1}, "768": {"slidesPerView": 1}, "1024": {"slidesPerView": 1}}'
      >
        <div class="swiper-wrapper pb-8">
          <div
            class="swiper-slide"
            style="
              background: url(https://localhost:5001/Content/images/pages/12.jpg)
                no-repeat;
              background-size: cover;
              border-radius: 0.5rem;
              background-position: center;
            "
          >
            <div class="lg:py-32 p-12 lg:pl-12 xl:w-2/5 md:w-3/5">
              <span
                class="inline-block p-2 text-sm align-baseline leading-none rounded-lg bg-yellow-500 text-gray-900 font-semibold"
                >Opening Sale Discount 50%</span
              >
              <div class="my-7 flex flex-col gap-2">
                <h1
                  class="text-white text-xl lg:text-5xl font-bold leading-tight"
                >
                  SuperMarket For Fresh Grocery
                </h1>
                <p class="text-md font-light text-white">
                  Introduced a new model for online grocery shopping and
                  convenient home delivery.
                </p>
              </div>
              <a
                href="#!"
                class="btn inline-flex items-center gap-x-2 bg-gray-800 text-white border-gray-800 disabled:opacity-50 disabled:pointer-events-none hover:text-white hover:bg-gray-900 hover:border-gray-900 active:bg-gray-900 active:border-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-300"
              >
                Comprar Ahora
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="icon icon-tabler icon-tabler-arrow-right inline-block"
                  width="14"
                  height="14"
                  viewBox="0 0 24 24"
                  stroke-width="2"
                  stroke="currentColor"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M5 12l14 0" />
                  <path d="M13 18l6 -6" />
                  <path d="M13 6l6 6" />
                </svg>
              </a>
            </div>
          </div>
          <div
            class="swiper-slide"
            style="
              background: url(assets/images/slider/slider-2.jpg) no-repeat;
              background-size: cover;
              border-radius: 0.5rem;
              background-position: center;
            "
          >
            <div
              class="lg:py-32 lg:pl-12 lg:pr-6 px-12 py-12 xl:w-2/5 md:w-3/5"
            >
              <span
                class="inline-block p-2 text-sm align-baseline leading-none rounded-lg bg-yellow-500 text-gray-900 font-semibold"
                >Free Shipping - orders over $100</span
              >
              <div class="my-7 flex flex-col gap-2">
                <h2
                  class="text-gray-900 text-xl lg:text-5xl font-bold leading-tight"
                >
                  Free Shipping on
                  <br />
                  orders over
                  <span class="text-orange-600">100</span>
                </h2>
                <p class="text-md font-light">
                  Free Shipping to First-Time Customers Only, After promotions
                  and discounts are applied.
                </p>
              </div>
              <a
                href="#!"
                class="btn inline-flex items-center gap-x-2 bg-gray-800 text-white border-gray-800 disabled:opacity-50 disabled:pointer-events-none hover:text-white hover:bg-gray-900 hover:border-gray-900 active:bg-gray-900 active:border-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-300"
              >
                Comprar Ahora
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="icon icon-tabler icon-tabler-arrow-right inline-block"
                  width="14"
                  height="14"
                  viewBox="0 0 24 24"
                  stroke-width="2"
                  stroke="currentColor"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M5 12l14 0" />
                  <path d="M13 18l6 -6" />
                  <path d="M13 6l6 6" />
                </svg>
              </a>
            </div>
          </div>

          <!-- Add more slides as needed -->
        </div>
        <!-- Add Pagination -->
        <div class="swiper-pagination !bottom-14"></div>
        <!-- Add Navigation -->
        <div class="swiper-navigation">
          <div class="swiper-button-next"></div>
          <div class="swiper-button-prev"></div>
        </div>
      </div>
    </div>
  </section>
  <section class="mt-8">
    <div class="container">
      <div class="flex flex-wrap">
        <div class="w-full">
          <h2 class="text-lg absolute z-10">Categorias Destacadas</h2>
        </div>
      </div>
      <div
        class="swiper-container swiper"
        id="swiper-1"
        data-pagination-type=""
        data-speed="400"
        data-space-between="20"
        data-pagination="false"
        data-navigation="true"
        data-autoplay="true"
        data-autoplay-delay="3000"
        data-effect="slide"
        data-breakpoints='{"480": {"slidesPerView": 2}, "768": {"slidesPerView": 3}, "1024": {"slidesPerView": 6}}'
      >
        <div class="swiper-wrapper py-12">
          <div class="swiper-slide" *ngFor="let category of categories">
            <a href="./shop-grid.html">
              <div
                class="relative rounded-lg break-words border bg-white border-gray-300 transition duration-75 hover:transition hover:duration-500 ease-in-out hover:border-orange-600 hover:shadow-md"
              >
                <div class="py-8 text-center">
                  <img
                    src="assets/images/category/category-dairy-bread-eggs.jpg"
                    alt="Grocery Ecommerce Template"
                    class="mb-3 m-auto"
                  />
                  <div class="text-base">{{ category.name }}</div>
                </div>
              </div>
            </a>
          </div>
        </div>

        <!-- Add Pagination -->
        <div class="swiper-pagination"></div>
        <!-- Add Navigation -->
        <div class="swiper-navigation">
          <div class="swiper-button-next top-[28px]"></div>
          <div
            class="swiper-button-prev top-[28px] !right-0 !left-auto mx-10"
          ></div>
        </div>
      </div>
    </div>
  </section>

  <section>
    <div class="container">
      <div class="flex md:space-x-2 lg:space-x-6 flex-wrap md:flex-nowrap">
        <div class="w-full md:w-1/2 mb-3 lg:">
          <div
            class="py-10 px-8 rounded-lg"
            style="
              background: url(assets/images/banner/grocery-banner.png) no-repeat;
              background-size: cover;
              background-position: center;
            "
          >
            <div class="flex flex-col gap-5">
              <div class="flex flex-col gap-1">
                <h2 class="font-bold text-xl">Moda y belleza</h2>
                <p>
                  Obten hast un
                  <span class="font-bold text-gray-800">30%</span>
                  de descuento
                </p>
              </div>

              <div class="flex flex-wrap">
                <a
                  href="#!"
                  class="btn inline-flex items-center gap-x-2 bg-gray-800 text-white border-gray-800 disabled:opacity-50 disabled:pointer-events-none hover:text-white hover:bg-gray-900 hover:border-gray-900 active:bg-gray-900 active:border-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-300"
                >
                  Comprar Ahora
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="w-full md:w-1/2">
          <div
            class="py-10 px-8 rounded-lg"
            style="
              background: url(assets/images/banner/grocery-banner-2.jpg)
                no-repeat;
              background-size: cover;
              background-position: center;
            "
          >
            <div class="flex flex-col gap-5">
              <div class="flex flex-col gap-1">
                <h2 class="font-bold text-xl">Artesanias</h2>
                <p>
                  Obten hast un
                  <span class="font-bold text-gray-800">25%</span>
                  de descuento
                </p>
              </div>

              <div class="flex flex-wrap">
                <a
                  href="#!"
                  class="btn inline-flex items-center gap-x-2 bg-gray-800 text-white border-gray-800 disabled:opacity-50 disabled:pointer-events-none hover:text-white hover:bg-gray-900 hover:border-gray-900 active:bg-gray-900 active:border-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-300"
                >
                  Comprar Ahora
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- Popular Products Start-->
  <section class="lg:my-14 my-8">
    <div class="container">
      <div class="flex flex-wrap">
        <div class="w-full mb-6">
          <h2 class="text-lg">Productos Populares</h2>
        </div>
      </div>

      <div
        class="grid gap-4 grid-cols-1 md:grid-cols-2 lg:gap-4 xl:grid-cols-5"
      >
        <ng-container *ngFor="let product of topSellers">
          <div
            class="relative rounded-lg break-words border bg-white border-gray-300 card-product"
          >
            <div class="flex-auto p-4">
              <div class="text-center relative flex justify-center">
                <div class="absolute top-0 left-0">
                  <span
                    class="inline-block p-1 text-center font-semibold text-sm align-baseline leading-none rounded bg-red-600 text-white"
                    >Sale</span
                  >
                </div>
                <a>
                  <img
                    [src]="product.images[0]"
                    [alt]="product.name"
                    class="w-full h-auto"
                  />
                </a>
                <div
                  class="absolute w-full bottom-[15%] opacity-0 invisible card-product-action"
                >
                  <a
                    class="h-[34px] w-[34px] leading-[34px] bg-white shadow inline-flex items-center justify-center rounded-lg hover:bg-green-600 hover:text-white"
                    data-bs-toggle="tooltip"
                    data-bs-html="true"
                    title="Quick View"
                  >
                    <span
                      data-bs-toggle="modal"
                      data-bs-target="#quickViewModal"
                      (click)="openModal(product)"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="icon icon-tabler icon-tabler-eye"
                        width="16"
                        height="16"
                        viewBox="0 0 24 24"
                        stroke-width="2"
                        stroke="currentColor"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <path
                          stroke="none"
                          d="M0 0h24v24H0z"
                          fill="none"
                        ></path>
                        <path d="M10 12a2 2 0 1 0 4 0a2 2 0 0 0 -4 0"></path>
                        <path
                          d="M21 12c-2.4 4 -5.4 6 -9 6c-3.6 0 -6.6 -2 -9 -6c2.4 -4 5.4 -6 9 -6c3.6 0 6.6 2 9 6"
                        ></path>
                      </svg>
                    </span>
                  </a>
                  <a
                    href="#!"
                    class="h-[34px] w-[34px] leading-[34px] bg-white shadow inline-flex items-center justify-center rounded-lg hover:bg-orange-600 hover:text-white"
                    data-bs-toggle="tooltip"
                    data-bs-html="true"
                    title="Wishlist"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="icon icon-tabler icon-tabler-heart"
                      width="16"
                      height="16"
                      viewBox="0 0 24 24"
                      stroke-width="2"
                      stroke="currentColor"
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                      <path
                        d="M19.5 12.572l-7.5 7.428l-7.5 -7.428a5 5 0 1 1 7.5 -6.566a5 5 0 1 1 7.5 6.572"
                      ></path>
                    </svg>
                  </a>
                  <a
                    href="#!"
                    class="h-[34px] w-[34px] leading-[34px] bg-white shadow inline-flex items-center justify-center rounded-lg hover:bg-orange-600 hover:text-white"
                    data-bs-toggle="tooltip"
                    data-bs-html="true"
                    title="Compare"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="icon icon-tabler icon-tabler-arrows-exchange"
                      width="16"
                      height="16"
                      viewBox="0 0 24 24"
                      stroke-width="2"
                      stroke="currentColor"
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                      <path d="M7 10h14l-4 -4"></path>
                      <path d="M17 14h-14l4 4"></path>
                    </svg>
                  </a>
                </div>
              </div>
              <div class="flex flex-col gap-3">
                <a href="#!" class="text-decoration-none text-gray-500"
                  ><small>{{ product.category1 }}</small></a
                >
                <div class="flex flex-col gap-2">
                  <h3 class="text-base truncate">
                    <a href="./shop-single.html">{{ product.name }}</a>
                  </h3>
                  <div class="flex items-center">
                    <div class="flex flex-row gap-3">
                      <small class="text-yellow-500 flex items-center">
                        <svg
                          *ngFor="
                            let star of getRatingArray(product.rating);
                            let last = last
                          "
                          xmlns="http://www.w3.org/2000/svg"
                          [class.icon-tabler-star-filled]="star === 1"
                          [class.icon-tabler-star-half-filled]="
                            last && product.rating % 1 !== 0
                          "
                          width="14"
                          height="14"
                          viewBox="0 0 24 24"
                          stroke-width="2"
                          stroke="currentColor"
                          fill="none"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        >
                          <path
                            stroke="none"
                            d="M0 0h24v24H0z"
                            fill="none"
                          ></path>
                          <path
                            *ngIf="star === 1"
                            d="M8.243 7.34l-6.38 .925l-.113 .023a1 1 0 0 0 -.44 1.684l4.622 4.499l-1.09 6.355l-.013 .11a1 1 0 0 0 1.464 .944l5.706 -3l5.693 3l.1 .046a1 1 0 0 0 1.352 -1.1l-1.091 -6.355l4.624 -4.5l.078 -.085a1 1 0 0 0 -.633 -1.62l-6.38 -.926l-2.852 -5.78a1 1 0 0 0 -1.794 0l-2.853 5.78z"
                            stroke-width="0"
                            fill="currentColor"
                          ></path>
                          <path
                            *ngIf="star === 0.5"
                            d="M12 1a.993 .993 0 0 1 .823 .443l.067 .116l2.852 5.781l6.38 .925c.741 .108 1.08 .94 .703 1.526l-.07 .095l-.078 .086l-4.624 4.499l1.09 6.355a1.001 1.001 0 0 1 -1.249 1.135l-.101 -.035l-.101 -.046l-5.693 -3l-5.706 3c-.105 .055 -.212 .09 -.32 .106l-.106 .01a1.003 1.003 0 0 1 -1.038 -1.06l.013 -.11l1.09 -6.355l-4.623 -4.5a1.001 1.001 0 0 1 .328 -1.647l.113 -.036l.114 -.023l6.379 -.925l2.853 -5.78a.968 .968 0 0 1 .904 -.56zm0 3.274v12.476a1 1 0 0 1 .239 .029l.115 .036l.112 .05l4.363 2.299l-.836 -4.873a1 1 0 0 1 .136 -.696l.07 -.099l.082 -.09l3.546 -3.453l-4.891 -.708a1 1 0 0 1 -.62 -.344l-.073 -.097l-.06 -.106l-2.183 -4.424z"
                            stroke-width="0"
                            fill="currentColor"
                          ></path>
                        </svg>
                      </small>
                      <div class="flex flex-row gap-1">
                        <span class="text-gray-500 text-sm">{{
                          product.rating
                        }}</span>
                        <span class="text-gray-500 text-sm"
                          >({{ product.reviews }})</span
                        >
                      </div>
                    </div>
                  </div>
                </div>

                <div class="flex justify-between items-center">
                  <div>
                    <span class="text-gray-900 font-semibold">{{
                      product.price | currency
                    }}</span>

                    <span
                      class="line-through text-gray-500"
                      *ngIf="product.oldPrice"
                      >{{ product.oldPrice | currency }}</span
                    >
                  </div>
                  <div>
                    <button
                      (click)="addItemToBasket(product)"
                      type="button"
                      class="btn inline-flex items-center gap-x-1 bg-orange-600 text-white border-orange-600 disabled:opacity-50 disabled:pointer-events-none hover:text-white hover:bg-orange-700 hover:border-orange-700 active:bg-orange-700 active:border-orange-700 focus:outline-none focus:ring-4 focus:ring-orange-300 btn-sm"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="icon icon-tabler icon-tabler-plus"
                        width="14"
                        height="14"
                        viewBox="0 0 24 24"
                        stroke-width="3"
                        stroke="currentColor"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <path
                          stroke="none"
                          d="M0 0h24v24H0z"
                          fill="none"
                        ></path>
                        <path d="M12 5l0 14"></path>
                        <path d="M5 12l14 0"></path>
                      </svg>
                      <span>Añadir</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </section>

  <section>
    <div class="container">
      <div class="flex flex-wrap">
        <div class="md:w-full mb-6">
          <h2 class="text-lg">Los más vendidos del día</h2>
        </div>
      </div>
      <div class="block w-full overflow-x-auto scrolling-touch pb-6">
        <div
          class="xl:grid gap-4 grid-cols-1 md:grid-cols-2 xl:grid-cols-4 flex-nowrap flex"
        >
          <div class="flex-0 block w-full md:w-auto">
            <div
              class="pt-8 px-6 rounded-lg"
              style="
                background: url(assets/images/banner/banner-deal.jpg) no-repeat;
                background-size: cover;
                height: 470px;
              "
            >
              <div class="flex flex-col gap-5">
                <div class="flex flex-col gap-2">
                  <h3 class="text-lg text-white">100% Organic Coffee Beans.</h3>
                  <p class="text-white text-base">
                    Get the best deal before close.
                  </p>
                </div>
                <div>
                  <a
                    href="#!"
                    class="btn inline-flex items-center gap-x-2 bg-orange-600 text-white border-orange-600 disabled:opacity-50 disabled:pointer-events-none hover:text-white hover:bg-orange-700 hover:border-orange-700 active:bg-orange-700 active:border-orange-700 focus:outline-none focus:ring-4 focus:ring-orange-300"
                  >
                    <span>Comprar Ahora</span>
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="icon icon-tabler icon-tabler-arrow-right"
                        width="14"
                        height="14"
                        viewBox="0 0 24 24"
                        stroke-width="2"
                        stroke="currentColor"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <path
                          stroke="none"
                          d="M0 0h24v24H0z"
                          fill="none"
                        ></path>
                        <path d="M5 12l14 0"></path>
                        <path d="M13 18l6 -6"></path>
                        <path d="M13 6l6 6"></path>
                      </svg>
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div
            *ngFor="let product of daySellers"
            class="flex-0 block w-full md:w-auto"
          >
            <div
              class="relative flex flex-col min-w-0 rounded-lg break-words border bg-white border-gray-300 card-product"
            >
              <div class="flex-auto p-4">
                <div class="text-center relative flex justify-center">
                  <a href="./shop-single.html">
                    <img
                      [src]="product.images[0]"
                      class="mb-3 m-auto max-w-full h-auto"
                    />
                  </a>
                  <div
                    class="absolute w-full bottom-[15%] opacity-0 invisible card-product-action"
                  >
                    <a
                      class="h-[34px] w-[34px] leading-[34px] bg-white shadow inline-flex items-center justify-center rounded-lg hover:bg-orange-600 hover:text-white"
                      data-bs-toggle="tooltip"
                      data-bs-html="true"
                      title="Quick View"
                    >
                      <span
                        data-bs-toggle="modal"
                        data-bs-target="#quickViewModal"
                        (click)="openModal(product)"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="icon icon-tabler icon-tabler-eye"
                          width="16"
                          height="16"
                          viewBox="0 0 24 24"
                          stroke-width="2"
                          stroke="currentColor"
                          fill="none"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        >
                          <path
                            stroke="none"
                            d="M0 0h24v24H0z"
                            fill="none"
                          ></path>
                          <path d="M10 12a2 2 0 1 0 4 0a2 2 0 0 0 -4 0"></path>
                          <path
                            d="M21 12c-2.4 4 -5.4 6 -9 6c-3.6 0 -6.6 -2 -9 -6c2.4 -4 5.4 -6 9 -6c3.6 0 6.6 2 9 6"
                          ></path>
                        </svg>
                      </span>
                    </a>
                    <a
                      href="#!"
                      class="h-[34px] w-[34px] leading-[34px] bg-white shadow inline-flex items-center justify-center rounded-lg hover:bg-orange-600 hover:text-white"
                      data-bs-toggle="tooltip"
                      data-bs-html="true"
                      title="Wishlist"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="icon icon-tabler icon-tabler-heart"
                        width="16"
                        height="16"
                        viewBox="0 0 24 24"
                        stroke-width="2"
                        stroke="currentColor"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <path
                          stroke="none"
                          d="M0 0h24v24H0z"
                          fill="none"
                        ></path>
                        <path
                          d="M19.5 12.572l-7.5 7.428l-7.5 -7.428a5 5 0 1 1 7.5 -6.566a5 5 0 1 1 7.5 6.572"
                        ></path>
                      </svg>
                    </a>
                    <a
                      href="#!"
                      class="h-[34px] w-[34px] leading-[34px] bg-white shadow inline-flex items-center justify-center rounded-lg hover:bg-orange-600 hover:text-white"
                      data-bs-toggle="tooltip"
                      data-bs-html="true"
                      title="Compare"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="icon icon-tabler icon-tabler-arrows-exchange"
                        width="16"
                        height="16"
                        viewBox="0 0 24 24"
                        stroke-width="2"
                        stroke="currentColor"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <path
                          stroke="none"
                          d="M0 0h24v24H0z"
                          fill="none"
                        ></path>
                        <path d="M7 10h14l-4 -4"></path>
                        <path d="M17 14h-14l4 4"></path>
                      </svg>
                    </a>
                  </div>
                </div>
                <div class="flex flex-col gap-3">
                  <a href="#!" class="text-decoration-none text-gray-500"
                    ><small>{{ product.category1 }}</small></a
                  >
                  <div class="flex flex-col gap-2">
                    <h3 class="text-base truncate">
                      <a href="./shop-single.html">{{ product.name }}</a>
                    </h3>
                    <div class="flex justify-between items-center">
                      <div>
                        <span class="text-gray-900 font-semibold">{{
                          product.price | currency
                        }}</span>
                        <span
                          class="line-through text-gray-500"
                          *ngIf="product.oldPrice"
                          >{{ product.oldPrice | currency }}</span
                        >
                      </div>
                      <div>
                        <div class="flex items-center">
                          <small class="text-yellow-500 flex items-center">
                            <svg
                              *ngFor="
                                let star of getRatingArray(product.rating);
                                let last = last
                              "
                              xmlns="http://www.w3.org/2000/svg"
                              [class.icon-tabler-star-filled]="star === 1"
                              [class.icon-tabler-star-half-filled]="
                                last && product.rating % 1 !== 0
                              "
                              width="14"
                              height="14"
                              viewBox="0 0 24 24"
                              stroke-width="2"
                              stroke="currentColor"
                              fill="none"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            >
                              <path
                                stroke="none"
                                d="M0 0h24v24H0z"
                                fill="none"
                              ></path>
                              <path
                                *ngIf="star === 1"
                                d="M8.243 7.34l-6.38 .925l-.113 .023a1 1 0 0 0 -.44 1.684l4.622 4.499l-1.09 6.355l-.013 .11a1 1 0 0 0 1.464 .944l5.706 -3l5.693 3l.1 .046a1 1 0 0 0 1.352 -1.1l-1.091 -6.355l4.624 -4.5l.078 -.085a1 1 0 0 0 -.633 -1.62l-6.38 -.926l-2.852 -5.78a1 1 0 0 0 -1.794 0l-2.853 5.78z"
                                stroke-width="0"
                                fill="currentColor"
                              ></path>
                              <path
                                *ngIf="star === 0.5"
                                d="M12 1a.993 .993 0 0 1 .823 .443l.067 .116l2.852 5.781l6.38 .925c.741 .108 1.08 .94 .703 1.526l-.07 .095l-.078 .086l-4.624 4.499l1.09 6.355a1.001 1.001 0 0 1 -1.249 1.135l-.101 -.035l-.101 -.046l-5.693 -3l-5.706 3c-.105 .055 -.212 .09 -.32 .106l-.106 .01a1.003 1.003 0 0 1 -1.038 -1.06l.013 -.11l1.09 -6.355l-4.623 -4.5a1.001 1.001 0 0 1 .328 -1.647l.113 -.036l.114 -.023l6.379 -.925l2.853 -5.78a.968 .968 0 0 1 .904 -.56zm0 3.274v12.476a1 1 0 0 1 .239 .029l.115 .036l.112 .05l4.363 2.299l-.836 -4.873a1 1 0 0 1 .136 -.696l.07 -.099l.082 -.09l3.546 -3.453l-4.891 -.708a1 1 0 0 1 -.62 -.344l-.073 -.097l-.06 -.106l-2.183 -4.424z"
                                stroke-width="0"
                                fill="currentColor"
                              ></path>
                            </svg>
                          </small>
                          <span class="text-gray-700 text-sm ml-2">{{
                            product.rating
                          }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="grid">
                    <button
                      type="button"
                      class="btn bg-orange-600 text-white border-orange-600 disabled:opacity-50 disabled:pointer-events-none hover:text-white hover:bg-orange-700 hover:border-orange-700 active:bg-orange-700 active:border-orange-700 focus:outline-none focus:ring-4 focus:ring-orange-300"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="icon icon-tabler icon-tabler-plus"
                        width="14"
                        height="14"
                        viewBox="0 0 24 24"
                        stroke-width="3"
                        stroke="currentColor"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <path
                          stroke="none"
                          d="M0 0h24v24H0z"
                          fill="none"
                        ></path>
                        <path d="M12 5l0 14"></path>
                        <path d="M5 12l14 0"></path>
                      </svg>
                      <span class="ml-1">Añadir a la cesta</span>
                    </button>
                  </div>
                  <div class="flex justify-start text-center">
                    <div
                      #countdown
                      class="deals-countdown w-full"
                      data-countdown="2028/10/10 00:00:00"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="lg:my-14 my-8">
    <div class="container">
      <div class="flex flex-wrap gap-y-6">
        <div class="md:w-1/2 lg:w-1/4 px-3">
          <div class="flex flex-col gap-4">
            <div class="inline-block">
              <img src="assets/images/icons/clock.svg" alt="" />
            </div>
            <div class="flex flex-col gap-2">
              <h3 class="text-md">10 minute grocery now</h3>
              <p>
                Get your order delivered to your doorstep at the earliest from
                FreshCart pickup stores near you.
              </p>
            </div>
          </div>
        </div>
        <div class="md:w-1/2 lg:w-1/4 px-3">
          <div class="flex flex-col gap-4">
            <div class="inline-block">
              <img src="assets/images/icons/gift.svg" alt="" />
            </div>
            <div class="flex flex-col gap-2">
              <h3 class="text-md">Best Prices & Offers</h3>
              <p>
                Cheaper prices than your local supermarket, great cashback
                offers to top it off. Get best pricess & offers.
              </p>
            </div>
          </div>
        </div>
        <div class="md:w-1/2 lg:w-1/4 px-3">
          <div class="flex flex-col gap-4">
            <div class="inline-block">
              <img src="assets/images/icons/package.svg" alt="" />
            </div>
            <div class="flex flex-col gap-2">
              <h3 class="text-md">Wide Assortment</h3>
              <p>
                Choose from 5000+ products across food, personal care,
                household, bakery, veg and non-veg & other categories.
              </p>
            </div>
          </div>
        </div>
        <div class="md:w-1/2 lg:w-1/4 px-3">
          <div class="flex flex-col gap-4">
            <div class="inline-block">
              <img src="assets/images/icons/refresh-cw.svg" alt="" />
            </div>
            <div class="flex flex-col gap-2">
              <h3 class="text-md">Easy Returns</h3>
              <p>
                Not satisfied with a product? Return it at the doorstep & get a
                refund within hours. No questions asked
                <a href="#!" class="text-orange-600">policy</a>
                .
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</main>
