<div class="modal fade" id="quickViewModal" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered lg:min-w-[1140px]">
    <div class="modal-content">
      <div class="modal-body p-8">
        <div class="absolute top-0 right-0 p-3">
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="icon icon-tabler icon-tabler-x text-gray-700"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              stroke-width="2"
              stroke="currentColor"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path d="M18 6l-12 12" />
              <path d="M6 6l12 12" />
            </svg>
          </button>
        </div>
        <div class="flex flex-wrap">
          <div class="md:w-1/2">
            <div class="product productModal" id="productModal">
              <div *ngFor="let image of product?.images; let i = index">
                <div
                  class="zoom"
                  (mousemove)="zoomService.zoom($event)"
                  [style.background-image]="'url(' + product?.images[i] + ')'"
                >
                  <img [src]="product?.images[i]" alt="" />
                </div>
              </div>
            </div>
            <div class="product-tools">
              <div class="thumbnails flex gap-3" id="productModalThumbnails">
                <div
                  class="w-1/4"
                  *ngFor="let image of product?.images; let i = index"
                >
                  <div class="thumbnails-img">
                    <img [src]="product?.images[i]" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="md:w-1/2 pr-4 pl-4">
            <div class="lg:pl-10 mt-6 md:mt-0">
              <div class="flex flex-col gap-4">
                <a href="#!" class="block text-green-600">{{
                  product?.category
                }}</a>
                <h1>{{ product?.name }}</h1>
                <div class="flex flex-col gap-2">
                  <div class="flex items-center">
                    <small class="text-yellow-500 flex items-center">
                      <svg
                        *ngFor="
                          let star of getRatingArray(product?.rating);
                          let last = last
                        "
                        xmlns="http://www.w3.org/2000/svg"
                        [class.icon-tabler-star-filled]="star === 1"
                        [class.icon-tabler-star-half-filled]="
                          last && product?.rating % 1 !== 0
                        "
                        width="14"
                        height="14"
                        viewBox="0 0 24 24"
                        stroke-width="2"
                        stroke="currentColor"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <path
                          stroke="none"
                          d="M0 0h24v24H0z"
                          fill="none"
                        ></path>
                        <path
                          *ngIf="star === 1"
                          d="M8.243 7.34l-6.38 .925l-.113 .023a1 1 0 0 0 -.44 1.684l4.622 4.499l-1.09 6.355l-.013 .11a1 1 0 0 0 1.464 .944l5.706 -3l5.693 3l.1 .046a1 1 0 0 0 1.352 -1.1l-1.091 -6.355l4.624 -4.5l.078 -.085a1 1 0 0 0 -.633 -1.62l-6.38 -.926l-2.852 -5.78a1 1 0 0 0 -1.794 0l-2.853 5.78z"
                          stroke-width="0"
                          fill="currentColor"
                        ></path>
                        <path
                          *ngIf="star === 0.5"
                          d="M12 1a.993 .993 0 0 1 .823 .443l.067 .116l2.852 5.781l6.38 .925c.741 .108 1.08 .94 .703 1.526l-.07 .095l-.078 .086l-4.624 4.499l1.09 6.355a1.001 1.001 0 0 1 -1.249 1.135l-.101 -.035l-.101 -.046l-5.693 -3l-5.706 3c-.105 .055 -.212 .09 -.32 .106l-.106 .01a1.003 1.003 0 0 1 -1.038 -1.06l.013 -.11l1.09 -6.355l-4.623 -4.5a1.001 1.001 0 0 1 .328 -1.647l.113 -.036l.114 -.023l6.379 -.925l2.853 -5.78a.968 .968 0 0 1 .904 -.56zm0 3.274v12.476a1 1 0 0 1 .239 .029l.115 .036l.112 .05l4.363 2.299l-.836 -4.873a1 1 0 0 1 .136 -.696l.07 -.099l.082 -.09l3.546 -3.453l-4.891 -.708a1 1 0 0 1 -.62 -.344l-.073 -.097l-.06 -.106l-2.183 -4.424z"
                          stroke-width="0"
                          fill="currentColor"
                        ></path>
                      </svg>
                    </small>
                    <a href="#" class="text-green-600">(30 reviews)</a>
                  </div>
                  <div class="text-md">
                    <span class="text-gray-900 font-semibold">
                      ${{ product?.price }}
                    </span>
                    <span class="line-through text-gray-500">
                      ${{ product?.oldPrice }}
                    </span>

                    <span><small class="text-red-600">26% Off</small></span>
                  </div>
                </div>
                <div class="flex flex-col gap-6">
                  <hr />
                  <div>
                    <button
                      type="button"
                      class="btn inline-flex items-center gap-x-2 bg-white text-gray-800 border-gray-300 border disabled:opacity-50 disabled:pointer-events-none hover:text-white hover:bg-gray-700 hover:border-gray-700 active:bg-gray-700 active:border-gray-700 focus:outline-none focus:ring-4 focus:ring-gray-300"
                    >
                      250g
                    </button>
                    <button
                      type="button"
                      class="btn inline-flex items-center gap-x-2 bg-white text-gray-800 border-gray-300 border disabled:opacity-50 disabled:pointer-events-none hover:text-white hover:bg-gray-700 hover:border-gray-700 active:bg-gray-700 active:border-gray-700 focus:outline-none focus:ring-4 focus:ring-gray-300"
                    >
                      500g
                    </button>
                    <button
                      type="button"
                      class="btn inline-flex items-center gap-x-2 bg-white text-gray-800 border-gray-300 border disabled:opacity-50 disabled:pointer-events-none hover:text-white hover:bg-gray-700 hover:border-gray-700 active:bg-gray-700 active:border-gray-700 focus:outline-none focus:ring-4 focus:ring-gray-300"
                    >
                      1kg
                    </button>
                  </div>
                  <div>
                    <div class="w-1/3 md:w-1/4 lg:w-1/5">
                      <div
                        class="input-group input-spinner rounded-lg flex justify-between items-center"
                      >
                        <input
                          type="button"
                          value="-"
                          class="button-minus w-8 py-1 border-r cursor-pointer border-gray-300"
                          data-field="quantity"
                        />
                        <input
                          type="number"
                          step="1"
                          max="10"
                          value="1"
                          name="quantity"
                          class="quantity-field w-9 px-2 text-center h-7 border-0 bg-transparent"
                        />
                        <input
                          type="button"
                          value="+"
                          class="button-plus w-8 py-1 border-l cursor-pointer border-gray-300"
                          data-field="quantity"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="flex flex-wrap justify-start gap-2 items-center">
                    <div class="lg:w-1/3 md:w-2/5 w-full grid">
                      <button
                        type="button"
                        class="btn bg-green-600 text-white border-green-600 disabled:opacity-50 disabled:pointer-events-none hover:text-white hover:bg-green-700 hover:border-green-700 active:bg-green-700 active:border-green-700 focus:outline-none focus:ring-4 focus:ring-green-300 justify-center"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="icon icon-tabler icon-tabler-plus mr-2"
                          width="12"
                          height="12"
                          viewBox="0 0 24 24"
                          stroke-width="3"
                          stroke="currentColor"
                          fill="none"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        >
                          <path
                            stroke="none"
                            d="M0 0h24v24H0z"
                            fill="none"
                          ></path>
                          <path d="M12 5l0 14"></path>
                          <path d="M5 12l14 0"></path>
                        </svg>
                        Add to cart
                      </button>
                    </div>
                    <div class="md:w-1/3 w-full">
                      <!-- btn -->
                      <a
                        href="#"
                        class="mr-1 btn inline-flex items-center gap-x-2 px-0 h-10 w-10 justify-center bg-white text-gray-800 border-gray-300 border disabled:opacity-50 disabled:pointer-events-none hover:text-white hover:bg-gray-700 hover:border-gray-700 active:bg-gray-700 active:border-gray-700 focus:outline-none focus:ring-4 focus:ring-gray-300"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="icon icon-tabler icon-tabler-arrows-exchange"
                          width="20"
                          height="20"
                          viewBox="0 0 24 24"
                          stroke-width="2"
                          stroke="currentColor"
                          fill="none"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        >
                          <path
                            stroke="none"
                            d="M0 0h24v24H0z"
                            fill="none"
                          ></path>
                          <path d="M7 10h14l-4 -4"></path>
                          <path d="M17 14h-14l4 4"></path>
                        </svg>
                      </a>
                      <a
                        href="#"
                        class="btn inline-flex items-center gap-x-2 px-0 h-10 w-10 justify-center bg-white text-gray-800 border-gray-300 border disabled:opacity-50 disabled:pointer-events-none hover:text-white hover:bg-gray-700 hover:border-gray-700 active:bg-gray-700 active:border-gray-700 focus:outline-none focus:ring-4 focus:ring-gray-300"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="icon icon-tabler icon-tabler-heart"
                          width="20"
                          height="20"
                          viewBox="0 0 24 24"
                          stroke-width="2"
                          stroke="currentColor"
                          fill="none"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        >
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <path
                            d="M19.5 12.572l-7.5 7.428l-7.5 -7.428a5 5 0 1 1 7.5 -6.566a5 5 0 1 1 7.5 6.572"
                          />
                        </svg>
                      </a>
                    </div>
                  </div>
                  <!-- hr -->
                  <hr />
                </div>
                <div>
                  <!-- table -->
                  <table class="text-left w-full">
                    <tbody>
                      <tr>
                        <td class="px-6 py-3">Product Code:</td>
                        <td class="px-6 py-3">FBB00255</td>
                      </tr>
                      <tr>
                        <td class="px-6 py-3">Availability:</td>
                        <td class="px-6 py-3">In Stock</td>
                      </tr>
                      <tr>
                        <td class="px-6 py-3">Type:</td>
                        <td class="px-6 py-3">Fruits</td>
                      </tr>
                      <tr>
                        <td class="px-6 py-3">Shipping:</td>
                        <td class="px-6 py-3">
                          <small>
                            01 day shipping.
                            <span class="text-gray-700"
                              >( Free pickup today)</span
                            >
                          </small>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div>
                  <!-- dropdown -->
                  <div class="relative">
                    <a
                      class="dropdown-toggle btn inline-flex items-center gap-x-2 bg-white text-gray-800 border-gray-300 border disabled:opacity-50 disabled:pointer-events-none hover:text-white hover:bg-gray-700 hover:border-gray-700 active:bg-gray-700 active:border-gray-700 focus:outline-none focus:ring-4 focus:ring-gray-300"
                      href="#"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      Share
                    </a>

                    <ul class="dropdown-menu">
                      <li>
                        <a class="dropdown-item" href="#">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="icon icon-tabler icon-tabler-brand-facebook inline-block"
                            width="18"
                            height="18"
                            viewBox="0 0 24 24"
                            stroke-width="2"
                            stroke="currentColor"
                            fill="none"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          >
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <path
                              d="M7 10v4h3v7h4v-7h3l1 -4h-4v-2a1 1 0 0 1 1 -1h3v-4h-3a5 5 0 0 0 -5 5v2h-3"
                            />
                          </svg>
                          Facebook
                        </a>
                      </li>
                      <li>
                        <a class="dropdown-item" href="#">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="icon icon-tabler icon-tabler-brand-x"
                            width="18"
                            height="18"
                            viewBox="0 0 24 24"
                            stroke-width="2"
                            stroke="currentColor"
                            fill="none"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          >
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <path d="M4 4l11.733 16h4.267l-11.733 -16z" />
                            <path
                              d="M4 20l6.768 -6.768m2.46 -2.46l6.772 -6.772"
                            />
                          </svg>
                          Twitter
                        </a>
                      </li>
                      <li>
                        <a class="dropdown-item" href="#">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="icon icon-tabler icon-tabler-brand-instagram"
                            width="18"
                            height="18"
                            viewBox="0 0 24 24"
                            stroke-width="2"
                            stroke="currentColor"
                            fill="none"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          >
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <path
                              d="M4 4m0 4a4 4 0 0 1 4 -4h8a4 4 0 0 1 4 4v8a4 4 0 0 1 -4 4h-8a4 4 0 0 1 -4 -4z"
                            />
                            <path
                              d="M12 12m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0"
                            />
                            <path d="M16.5 7.5l0 .01" />
                          </svg>
                          Instagram
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
