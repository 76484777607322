import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/guards/auth.guard';
import { NotFoundComponent } from './core/not-found/not-found.component';
import { ServerErrorComponent } from './core/server-error/server-error.component';
import { TestErrorComponent } from './core/test-error/test-error.component';
import { HomeComponent } from './home/home.component';
import { CompanyOverviewComponent } from './account/profile/company-overview/company-overview.component';
import { AtencionAlClienteComponent } from './pages/atencion-al-cliente/atencion-al-cliente.component';
import { SeguridadEnvioComponent } from './pages/seguridad-envio/pagos.component';
import { Home2Component } from './home/home2/home2.component';
import { AboutComponent } from './pages/about/about.component';
import { SobreNosotrosComponent } from './pages/sobre-nosotros/sobre-nosotros.component';
import { ValoresComponent } from './pages/valores/valores.component';
import { EnviosComponent } from './pages/envios/envios.component';

const routes: Routes = [
  { path: '', component: Home2Component, data: { breadcrumb: 'Home' } },
  { path: 'home', component: Home2Component, data: { breadcrumb: 'Home' } },
  {
    path: 'tienda/:id',
    component: CompanyOverviewComponent,
    data: { breadcrumb: { skip: true } },
  },
  { path: 'test-error', component: TestErrorComponent },
  { path: 'not-found', component: NotFoundComponent },
  { path: 'server-error', component: ServerErrorComponent },

  {
    path: 'atencion-a-clientes',
    component: AtencionAlClienteComponent,
    data: { breadcrumb: { skip: true } },
  },
  {
    path: 'pagos',
    component: SeguridadEnvioComponent,
    data: { breadcrumb: { skip: true } },
  },
  {
    path: 'envios',
    component: EnviosComponent,
    data: { breadcrumb: { skip: true } },
  },
  {
    path: 'about',
    component: AboutComponent,
    data: { breadcrumb: { skip: true } },
  },
  {
    path: 'conocenos',
    component: SobreNosotrosComponent,
    data: { breadcrumb: { skip: true } },
  },
  {
    path: 'valores',
    component: ValoresComponent,
    data: { breadcrumb: { skip: true } },
  },
  {
    path: 'shop',
    loadChildren: () => import('./shop/shop.module').then((m) => m.ShopModule),
    data: { breadcrumb: { skip: true } },
  },
  {
    path: 'basket',
    loadChildren: () =>
      import('./basket/basket.module').then((m) => m.BasketModule),
  },
  {
    path: 'checkout',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./checkout/checkout.module').then((m) => m.CheckoutModule),
  },
  {
    path: 'payment',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./payment/payment.module').then((m) => m.PaymentModule),
  },
  {
    path: 'orders',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./orders/orders.module').then((m) => m.OrdersModule),
  },
  {
    path: 'account',
    loadChildren: () =>
      import('./account/account.module').then((m) => m.AccountModule),
    data: { breadcrumb: { skip: true } },
  },
  { path: '**', redirectTo: '', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
