<!-- Modal -->
<div
  class="modal fade"
  id="userModal"
  tabindex="-1"
  aria-labelledby="userModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content p-8">
      <div class="flex justify-between items-center mb-4">
        <h3 class="font-bold text-gray-800" id="userModalLabel">Acceso</h3>

        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="icon icon-tabler icon-tabler-x text-gray-700"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            stroke-width="2"
            stroke="currentColor"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path d="M18 6l-12 12" />
            <path d="M6 6l12 12" />
          </svg>
        </button>
      </div>
      <div class="modal-body">
        <form
          class="needs-validation"
          novalidate
          [formGroup]="loginForm"
          (ngSubmit)="onSubmit()"
        >
          <app-text-input
            [formControl]="loginForm.controls['email']"
            [label]="'Correo eléctronico'"
          ></app-text-input>

          <app-text-input
            [formControl]="loginForm.controls['password']"
            [label]="'Contraseña'"
            [type]="'password'"
          ></app-text-input>

          <button
            type="submit"
            class="btn inline-flex items-center gap-x-2 bg-orange-600 text-white border-orange-600 disabled:opacity-50 disabled:pointer-events-none hover:text-white hover:bg-orange-700 hover:border-orange-700 active:bg-orange-700 active:border-orange-700 focus:outline-none focus:ring-4 focus:ring-orange-300 justify-center"
          >
            Login
          </button>
        </form>
      </div>

      <div class="modal-footer flex flex-col border-0 items-center mt-3">
        <div>O iniciar sesión con:</div>
        <asl-google-signin-button
          class="mx-1"
          size="large"
        ></asl-google-signin-button>
        <button (click)="signInWithFB()" class="btn btn-blue">
          <i class="fa-brands fa-facebook fa-2xl"></i>
        </button>
        <div class="flex flex-row items-center">
          <div>Regístrate como:</div>

          <a
            routerLink="/account/register/client"
            data-bs-dismiss="modal"
            aria-label="Close"
            class="text-orange-600 ml-1"
            >Cliente</a
          >
          <a
            routerLink="/account/register/provider"
            data-bs-dismiss="modal"
            aria-label="Close"
            class="text-orange-600 ml-1"
            >Vendedor</a
          >
        </div>
      </div>
    </div>
  </div>
</div>
