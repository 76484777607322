<main>
  <!-- section -->
  <section class="my-10">
    <!-- container -->
    <div class="container">
      <div class="flex flex-wrap justify-center">
        <div class="lg:mx-1/6 xl:w-4/5 w-full">
          <div class="flex gap-6 flex-wrap md:flex-nowrap items-center mb-8">
            <div class="md:w-full">
              <!-- text -->
              <div class="lg:mx-20 flex flex-col gap-4">
                <h1 class="text-lg leading-tight">Sobre Nosotros</h1>
                <p class="text-md text-justify">
                  En <strong>Viammex</strong> , nuestra misión es clara:
                  conectar a vendedores y productores mexicanos con compradores
                  dentro de México, creando un ecosistema comercial que
                  fortalezca la economía local y fomente el consumo nacional.
                  Somos una plataforma que celebra la riqueza y diversidad de
                  los productos hechos en México, brindando a los negocios la
                  oportunidad de crecer mientras impulsamos relaciones
                  comerciales confiables.
                </p>
              </div>

              <div class="lg:mx-20 flex flex-col gap-4 mt-4">
                <h1 class="text-lg leading-tight">
                  Un Espacio Exclusivamente Mexicano
                </h1>
                <p class="text-md text-justify">
                  Viammex nace con el propósito de apoyar a los negocios
                  mexicanos, ofreciendo una solución accesible y efectiva para
                  conectar a quienes producen con quienes buscan abastecerse o
                  encontrar productos de calidad. Aquí, tanto vendedores como
                  compradores comparten un compromiso por consumir y vender lo
                  mejor que México tiene para ofrecer.
                </p>
              </div>

              <div class="lg:mx-20 flex flex-col gap-4 mt-4">
                <h1 class="text-lg leading-tight">
                  Nuestro Compromiso con los Vendedores
                </h1>
                <p class="text-md text-justify">
                  Sabemos lo importante que es encontrar un espacio donde puedas
                  dar a conocer tus productos y llegar a compradores que valoren
                  la calidad y el esfuerzo detrás de cada artículo. Por eso,
                  estamos contigo en cada paso del camino para asegurarnos de
                  que tengas las herramientas y el soporte necesarios para
                  alcanzar tus objetivos.
                </p>
                <li class="text-md text-justify">
                  <strong>Impulso para tus productos:</strong> Ayudamos a que
                  tus productos destaquen en el mercado nacional, brindándote
                  visibilidad ante compradores mexicanos interesados en apoyar
                  el comercio local.
                </li>
                <li class="text-md text-justify">
                  <strong>Soporte continuo:</strong> Si encuentras desafíos al
                  gestionar tus ventas o productos, nuestro equipo está listo
                  para ofrecerte soluciones rápidas y efectivas.
                </li>
                <li class="text-md text-justify">
                  <strong>Espacio para crecer:</strong> Desde pequeños
                  productores hasta grandes empresas, Viammex es un aliado para
                  todos los que quieran expandir su presencia en el mercado
                  mexicano.
                </li>
              </div>

              <div class="lg:mx-20 flex flex-col gap-4 mt-4">
                <h1 class="text-lg leading-tight">
                  Compromiso con los Compradores
                </h1>
                <p class="text-md text-justify">
                  En Viammex, garantizamos que los compradores mexicanos
                  encuentren productos auténticos y de alta calidad verificados
                  por Viammex, con la confianza de que cada artículo proviene de
                  un negocio nacional comprometido con la excelencia.
                </p>
                <li class="text-md text-justify">
                  <strong>Productos hechos en México:</strong> Ofrecemos una
                  plataforma exclusiva para productos mexicanos, respaldada por
                  vendedores locales que comparten la pasión por ofrecer lo
                  mejor.
                </li>
                <li class="text-md text-justify">
                  <strong>Atención personalizada: </strong> Nuestro equipo de
                  soporte está siempre disponible para responder a tus dudas,
                  resolver problemas y asegurarte una experiencia de compra
                  satisfactoria.
                </li>
                <li class="text-md text-justify">
                  <strong>Transparencia y confianza: </strong> Trabajamos para
                  validar a nuestros vendedores y garantizar que los productos
                  cumplan con las expectativas de nuestros compradores.
                </li>
              </div>

              <div class="lg:mx-20 flex flex-col gap-4 mt-4">
                <h1 class="text-lg leading-tight">
                  Fomentando el Consumo Local
                </h1>
                <p class="text-md text-justify">
                  Creemos firmemente en el poder del consumo local para
                  transformar la economía y generar oportunidades. Viammex no es
                  solo una plataforma; es un movimiento que impulsa el talento,
                  la creatividad y el esfuerzo de los mexicanos.
                </p>

                <p class="text-md text-justify">
                  Con cada transacción, fortalecemos a las empresas locales,
                  reducimos la dependencia de productos importados y fomentamos
                  una economía más sostenible y equitativa.
                </p>
              </div>

              <div class="lg:mx-20 flex flex-col gap-4 mt-4">
                <h1 class="text-lg leading-tight">Siempre Contigo</h1>
                <p class="text-md text-justify">
                  Lo que realmente nos distingue es nuestro compromiso por estar
                  contigo en cada paso del camino. Ya seas un vendedor que busca
                  crecer o un comprador que valora la calidad, en Viammex
                  encontrarás una comunidad comprometida con tus objetivos.
                </p>
                <li class="text-md text-justify">
                  <strong>Soporte cercano:</strong> Nuestro equipo está dedicado
                  a atender tus necesidades, ya sea para resolver dudas
                  técnicas, optimizar tu experiencia o ayudarte a resolver
                  cualquier inconveniente.
                </li>
                <li class="text-md text-justify">
                  <strong>Plataforma fácil de usar: </strong> Diseñamos Viammex
                  para que sea una herramienta accesible y efectiva, adecuada
                  para negocios de todos los tamaños.
                </li>
                <li class="text-md text-justify">
                  <strong>Colaboración nacional: </strong> Trabajamos contigo
                  para construir relaciones comerciales sólidas que beneficien a
                  todos los involucrados.
                </li>
              </div>

              <div class="lg:mx-20 flex flex-col gap-4 mt-4">
                <h1 class="text-lg leading-tight">Nuestra Visión</h1>
                <p class="text-md text-justify">
                  En Viammex, soñamos con un México donde los negocios locales
                  prosperen y los consumidores valoren los productos nacionales.
                  Queremos ser la plataforma líder en conectar a vendedores y
                  compradores mexicanos, impulsando el crecimiento económico y
                  fomentando una comunidad de negocios fuerte y vibrante.
                </p>
                <p class="text-md text-justify">
                  Si compartes nuestra visión, únete a Viammex y sé parte del
                  cambio. Estamos aquí para ayudarte a crecer y hacer de México
                  un referente en calidad y comercio local.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</main>
