import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PagingHeaderComponent } from './paging-header/paging-header.component';
import { PagerComponent } from './pager/pager.component';
import { OrderTotalsComponent } from './order-totals/order-totals.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TextInputComponent } from './components/text-input/text-input.component';
import { StepperComponent } from './components/stepper/stepper.component';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { BasketSummaryComponent } from './basket-summary/basket-summary.component';
import { RouterModule } from '@angular/router';
import { PrimeModule } from './prime.module';
import { CustomFieldComponent } from './custom-field-component/custom-field.component';
import { MaterialModule } from './material.module';
import { UserMenuComponent } from './components/user-menu/user-menu.component';
import { ShippingCostsModalComponent } from './shipping-costs-modal/shipping-costs-modal.component';
import { IncluirHtmlDirective } from './directives/incluir-html.directive';
import { FooterComponent } from './components/footer/footer.component';
import { LoginModalComponent } from './components/login-modal/login-modal.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { ProductModalComponent } from './components/product-modal/product-modal.component';
import { ShopCartModalComponent } from './components/shop-cart-modal/shop-cart-modal.component';
import { GoogleSigninButtonModule } from '@abacritt/angularx-social-login';

@NgModule({
  declarations: [
    PagingHeaderComponent,
    PagerComponent,
    OrderTotalsComponent,
    TextInputComponent,
    StepperComponent,
    BasketSummaryComponent,
    CustomFieldComponent,
    UserMenuComponent,
    ShippingCostsModalComponent,
    IncluirHtmlDirective,
    NavbarComponent,
    FooterComponent,
    ProductModalComponent,
    LoginModalComponent,
    ShopCartModalComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    CdkStepperModule,
    RouterModule,
    PrimeModule,
    MaterialModule,
    GoogleSigninButtonModule,
  ],
  exports: [
    PagingHeaderComponent,
    PagerComponent,
    OrderTotalsComponent,
    ReactiveFormsModule,
    TextInputComponent,
    StepperComponent,
    CdkStepperModule,
    BasketSummaryComponent,
    PrimeModule,
    CustomFieldComponent,
    UserMenuComponent,
    MaterialModule,
    ShippingCostsModalComponent,
    IncluirHtmlDirective,
    NavbarComponent,
    FooterComponent,
    ProductModalComponent,
    LoginModalComponent,
    ShopCartModalComponent,
    GoogleSigninButtonModule,
  ],
})
export class SharedModule {}
