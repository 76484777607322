import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  QueryList,
  TemplateRef,
  ViewChild,
  ViewChildren,
  ViewEncapsulation,
} from '@angular/core';
import { Router } from '@angular/router';
import { shuffle } from 'lodash';
import { MenuItem } from 'primeng/api';
import { AccountService } from 'src/app/account/account.service';
import { BasketService } from 'src/app/basket/basket.service';
import { Product } from 'src/app/shared/models/product';
import { ShopParams } from 'src/app/shared/models/shopParams';
import { CountdownService } from 'src/app/shared/services/countdown.service';
import { SwiperService } from 'src/app/shared/services/swiper.service';
import { TinySliderService } from 'src/app/shared/services/tiny-slider.service';
import { ShopService } from 'src/app/shop/shop.service';
import { environment } from 'src/environments/environment';
import { tns } from 'tiny-slider/src/tiny-slider';

@Component({
  selector: 'app-home2',
  templateUrl: './home2.component.html',
  styleUrls: ['./home2.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class Home2Component implements OnInit, OnDestroy, AfterViewInit {
  imagesIndustrial: Product[] = [];
  imagesModa: Product[] = [];
  imagesSalud: Product[] = [];
  products: Product[] = [];
  topSellers: Product[] = [];
  daySellers: Product[] = [];

  selectedProduct!: Product;
  @ViewChild('search') searchTerm?: ElementRef;
  shopParams: ShopParams;
  url: string;
  totalSlides = [1, 2, 4, 6, 8, 9]; // Total number of slides
  urlReg = environment.contentUrl + 'images/pages/12.jpg';
  items: MenuItem[] | undefined;
  categories: any;
  @ViewChild('quickViewModal') modalContent!: TemplateRef<any>;
  @ViewChildren('countdown') countdownElements!: QueryList<ElementRef>;

  constructor(
    private shopService: ShopService,
    private router: Router,
    public accountService: AccountService,
    private basketService: BasketService,
    private swiperService: SwiperService,
    private tinySliderService: TinySliderService,
    private countdownService: CountdownService,
    private cdRef: ChangeDetectorRef,
  ) {
    this.shopParams = new ShopParams();
    this.shopService.setShopParams(this.shopParams);

    this.url = environment.contentUrl + 'images/banner/';
    this.getProducts();
    this.shopParams = shopService.getShopParams();
    this.getCategories();
  }

  ngOnInit() {
    this.swiperService.initializeSwiperCarousels();

    const modalElement = document.getElementById('quickViewModal');
    if (modalElement) {
      modalElement.addEventListener('shown.bs.modal', () => {
        this.tinySliderService.initializeTinySliders();
      });
    }
  }

  ngAfterViewInit() {
    this.countdownElements.forEach((element) => {
      this.countdownService.initializeCountdown(element.nativeElement);
    });
  }

  ngOnDestroy() {}

  getRatingArray(rating: number | 0): number[] {
    // Cambiamos any[] a number[]
    const fullStars = Math.floor(rating);
    const hasHalfStar = rating - fullStars >= 0.5;
    const ratingArray = Array(fullStars).fill(1); // Rellenamos con 1 (o cualquier número)
    if (hasHalfStar) {
      ratingArray.push(0.5); // Agregamos 0.5 para la media estrella
    }
    return ratingArray;
  }

  openModal(product: any) {
    this.selectedProduct = product;
  }

  addItemToBasket(product: Product) {
    product && this.basketService.addItemToBasket(product);
  }

  getCategories() {
    this.shopService.getProductCategory1().subscribe({
      next: (response) => {
        this.categories = response;

        this.items = response.map((categoria) => ({
          label: categoria.name,
          command: () => {
            this.onSearch(categoria.id);
          },
        }));
      },
      error: (error) => console.log(error),
    });
  }

  getTopSellerProducts() {
    const shuffledProducts = shuffle(this.products);

    // Seleccionar los primeros 8 productos mezclados
    this.topSellers = shuffledProducts.slice(0, 10);
  }

  getDaySellerProducts() {
    const shuffledProducts = shuffle(this.products);

    // Seleccionar los primeros 8 productos mezclados
    this.daySellers = shuffledProducts.slice(0, 3);
  }
  getProducts() {
    this.shopService.getProducts().subscribe({
      next: (response) => {
        this.products = response.data;
        this.imagesModa = response.data.filter(
          (x) => x.category1 == 'Moda y Belleza ',
        );
        this.imagesSalud = response.data.filter((x) => x.category1 == 'Salud');
        this.imagesIndustrial = response.data.filter(
          (x) => x.category1 == 'Industrial',
        );

        this.products.forEach((x) => {
          x.images = [x.pictureUrl];
          x.rating = this.generarRating();
          x.reviews = this.generarReviews();
        });
        this.getTopSellerProducts();
        this.getDaySellerProducts();
      },
      error: (error) => console.log(error),
    });
  }

  generarRating(): number {
    const min = 0;
    const max = 5;
    const step = 0.5;

    // Generar un número aleatorio entre 0 y 10 (inclusive)
    const random = Math.floor(Math.random() * ((max - min) / step + 1));

    // Calcular el rating multiplicando el número aleatorio por el paso y sumando el mínimo
    const rating = random * step + min;

    return rating;
  }

  generarReviews(): number {
    const min = 0;
    const max = 10000;
    const step = 1;

    // Generar un número aleatorio entre 0 y 10000 (inclusive)
    const random = Math.floor(Math.random() * ((max - min) / step + 1));

    // Calcular el número de reviews multiplicando el número aleatorio por el paso y sumando el mínimo
    const reviews = random * step + min;

    return reviews;
  }

  onSearch(id: number) {
    const params = this.shopService.getShopParams();
    params.categoryId = id;
    params.search = this.searchTerm?.nativeElement.value;
    params.pageNumber = 1;
    this.shopService.setShopParams(params);
    this.shopParams = params;
    this.getProducts();
    this.router.navigate(['/shop']);
  }

  onReset() {
    if (this.searchTerm) this.searchTerm.nativeElement.value = '';
    this.shopParams = new ShopParams();
    this.shopService.setShopParams(this.shopParams);
    this.getProducts();
  }
}
