<section class="my-10">
  <!-- container -->
  <div class="container">
    <div class="flex flex-wrap justify-center">
      <div class="lg:mx-1/6 xl:w-4/5 w-full">
        <div class="flex gap-6 flex-wrap md:flex-nowrap items-center mb-8">
          <div class="md:w-1/2">
            <!-- text -->
            <div class="lg:mx-20 flex flex-col gap-4">
              <h1 class="text-2xl leading-tight">Centro de Ayuda:</h1>
              <p class="text-md text-justify">
                Pagina principal de donde sale atención a cliente, atención a
                proveedores, Preguntas Frecuentes, entre otras. Todo lo
                necesario para la asistencia de los clientes y proveedores de
                Viammex, teléfonos y correos entre otras cosas. Considerar poner
                un chat en vivo de inteligencia artificial con las fallas
                básicas y referir a hablar con un representante para temas mas
                complejos.
              </p>
            </div>
          </div>

          <div class="md:w-1/2 w-full">
            <!-- img -->
            <img
              src="{{ url }}"
              alt=""
              class="max-w-full w-full h-auto rounded-lg"
            />
          </div>
        </div>

        <div class="flex flex-wrap gap-6 mb-8">
          <div class="w-full">
            <!-- heading -->
            <h2 class="text-xl">Atención a Clientes</h2>
          </div>
          <div class="grid md:grid-cols-3 gap-6">
            <!-- card -->
            <div class="card bg-gray-100 border-0">
              <!-- card body -->
              <div class="card-body p-8 flex flex-col gap-5">
                <div class="flex flex-col gap-2">
                  <p class="text-justify">
                    ¡Estamos para ti en lo que necesites! Cuenta con nosotros
                    para cualquier tema logístico, de transacción o de lo que se
                    ofrezca dentro de tu proceso de compra con Viammex.
                  </p>
                </div>
              </div>
            </div>

            <!-- card -->
            <div class="card bg-gray-100 border-0">
              <!-- card body -->
              <div class="card-body p-8 flex flex-col gap-5">
                <div class="flex flex-col gap-2">
                  <p class="text-justify">
                    Recuerda que nuestro equipo estará disponible de 8 am a 5 pm
                    en el teléfono_____________ o nos puede mandar un mail a
                    <a href="mailto:atencionaclientes@viammex.com"
                      >atencionaclientes&#64;viammex.com</a
                    >
                    y contestaremos en menos de 24 horas.
                  </p>
                </div>
              </div>
            </div>

            <div class="card bg-gray-100 border-0">
              <div class="card-body p-8 flex flex-col gap-5">
                <div class="flex flex-col gap-2">
                  <p class="text-justify">
                    Nosotros podemos ayudar a resolver los temas a resolver
                    entre proveedor y cliente siempre y cuando sea de una
                    transacción hecha a través de nuestra plataforma.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
