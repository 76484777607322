import { Injectable } from '@angular/core';
import Swiper from 'swiper';
import { Navigation, Pagination, Autoplay, EffectFade } from 'swiper/modules';
import { SwiperOptions } from 'swiper/types';
import { parseSwiperConfig } from '../utils/swiper.utils';
import { SwiperEffect, SwiperPaginationType } from '../types/swiper.types';

@Injectable({
  providedIn: 'root',
})
export class SwiperService {
  private swipers: Swiper[] = [];

  initializeSwiperCarousels(): void {
    const swiperContainers = document.querySelectorAll('.swiper-container');

    swiperContainers.forEach((container) => {
      const swiperContainer = container as HTMLElement;
      const config = parseSwiperConfig(swiperContainer);

      const swiperOptions: SwiperOptions = {
        modules: [Navigation, Pagination, Autoplay, EffectFade],
        speed: config.speed,
        spaceBetween: config.spaceBetween,
        breakpoints: config.breakpoints,
        effect: config.effect as SwiperEffect,
      };

      if (config.effect === 'fade') {
        swiperOptions.fadeEffect = { crossFade: true };
      }

      if (config.paginationEnabled) {
        swiperOptions.pagination = this.configurePagination(
          swiperContainer,
          config.paginationType,
        );
      }

      if (config.navigationEnabled) {
        swiperOptions.navigation = {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        };
      } else {
        this.hideNavigation(swiperContainer);
      }

      if (config.autoplayEnabled) {
        swiperOptions.autoplay = { delay: config.autoplayDelay };
      }

      const swiper = new Swiper(swiperContainer, swiperOptions);
      this.swipers.push(swiper);
    });
  }

  private configurePagination(container: HTMLElement, type: string) {
    const paginationEl = container.querySelector('.swiper-pagination');
    if (!paginationEl) return {};

    return {
      el: paginationEl,
      type: type as SwiperPaginationType,
      dynamicBullets: true,
      clickable: true,
      ...(type === 'custom' && {
        renderCustom: this.renderCustomPagination,
      }),
    };
  }

  private renderCustomPagination(
    swiper: Swiper,
    current: number,
    total: number,
  ): string {
    return Array.from({ length: total }, (_, i) => i + 1)
      .map(
        (i) =>
          `<span class="swiper-pagination-numbers${
            current === i ? ' swiper-pagination-numbers-active' : ''
          }">${i}</span>`,
      )
      .join('');
  }

  private hideNavigation(container: HTMLElement): void {
    const navigationEl = container.querySelector('.swiper-navigation');
    if (navigationEl) {
      navigationEl.classList.add('swiper-navigation-hidden');
    }
  }

  destroyAll(): void {
    this.swipers.forEach((swiper) => {
      if (swiper?.destroy) swiper.destroy();
    });
    this.swipers = [];
  }

  getSwiper(index: number): Swiper | undefined {
    return this.swipers[index];
  }
}
