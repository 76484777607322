import {
  FacebookLoginProvider,
  GoogleLoginProvider,
  SocialAuthService,
} from '@abacritt/angularx-social-login';
import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountService } from 'src/app/account/account.service';

@Component({
  selector: 'app-login-modal',
  templateUrl: './login-modal.component.html',
  styleUrls: ['./login-modal.component.scss'],
})
export class LoginModalComponent {
  returnUrl: string;
  loginForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', Validators.required),
  });

  constructor(
    private accountService: AccountService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private authService: SocialAuthService,
  ) {
    this.returnUrl =
      this.activatedRoute.snapshot.queryParams['returnUrl'] || '/shop';
  }
  ngOnInit(): void {
    this.authService.authState.subscribe((user) => {
      if (user.provider === GoogleLoginProvider.PROVIDER_ID) {
        // El usuario inició sesión con Google
        this.accountService.loginGoogle(user.idToken).subscribe({
          next: () => this.router.navigateByUrl(this.returnUrl),
        });
      } else if (user.provider === FacebookLoginProvider.PROVIDER_ID) {
        // El usuario inició sesión con Facebook
        this.accountService.loginFacebook(user.authToken).subscribe({
          // Asumiendo que tienes un método loginFacebook en tu accountService
          next: () => this.router.navigateByUrl(this.returnUrl),
        });
      }
    });
  }

  onSubmit() {
    this.accountService.login(this.loginForm.value).subscribe({
      next: () => this.router.navigateByUrl(this.returnUrl),
    });
  }

  signInWithFB(): void {
    this.authService.signIn(FacebookLoginProvider.PROVIDER_ID);
  }
}
