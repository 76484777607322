<div class="form-floating mb-3 w-full">
  <input
    type="{{ type }}"
    [formControl]="control"
    placeholder="{{ label }}"
    class="form-control border border-gray-300 text-gray-900 rounded-lg focus:shadow-[0_0_0_.25rem_rgba(10,173,10,.25)] focus:ring-orange-600 focus:ring-0 focus:border-orange-600 block p-2 px-3 disabled:opacity-50 disabled:pointer-events-none w-full text-base"
    [ngClass]="
      control.touched ? (control.invalid ? 'is-invalid' : 'is-valid') : null
    "
  />
  <div
    *ngIf="control.status === 'PENDING'"
    class="fa fa-spinner fa-spin loader"
  ></div>
  <label for="floatingInput">{{ label }}</label>
  <div *ngIf="control.errors?.['required']" class="invalid-feedback">
    {{ label }} obligatorio
  </div>
  <div *ngIf="control.errors?.['email']" class="invalid-feedback">
    Dirección de correo electrónico no válida
  </div>
  <div *ngIf="control.errors?.['pattern']" class="invalid-feedback">
    Tu contraseña debe tener:
    <ul>
      <li>Al menos una letra mayúscula (como "A", "B", "C"...)</li>
      <li>Al menos una letra minúscula (como "a", "b", "c"...)</li>
      <li>Al menos un número (del 0 al 9)</li>
      <li>Al menos un símbolo (como "!", "&#64;", "#", "$", "%", etc.)</li>
      <li>Y debe tener al menos 6 caracteres en total.</li>
    </ul>
    ¡Así tu cuenta estará más segura! 😉
  </div>
  <div *ngIf="control.errors?.['emailExists']" class="invalid-feedback">
    La dirección de correo electrónico ya está en uso
  </div>
</div>
