<!--
<header class="bg-white shadow">
  <nav class="container mx-auto px-4 py-4">
    <div class="flex items-center justify-between">
      <a routerLink="/" class="flex-shrink-0">
        <img src="/assets/images/logo.jpg" alt="Viammex Logo" class="h-12" />
      </a>
      <div class="mx-4 flex-grow hidden md:block">
        <form class="flex">
          <input
            class="focus:outline-none focus:ring-2 focus:ring-blue-500 w-full rounded-l-md border border-gray-300 px-4 py-2"
            type="search"
            (keyup.enter)="onSearch()"
            placeholder="Buscar productos2..."
          />
          <button
            class="hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 rounded-r-md bg-blue-500 px-4 py-2 text-white"
            type="submit"
            (click)="onSearch()"
          >
            <i class="pi pi-search"></i>
          </button>
        </form>
      </div>

      <button
        routerLink="/basket"
        class="justify-end hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-500 position-relative rounded-full p-2"
      >
        <i class="fa fa-shopping-cart fa-2x" style="color: #00adef"> </i>
        <div
          *ngIf="basketService.basketSource$ | async as basket"
          class="cart-no"
        >
          {{ getCount(basket.items) }}
        </div>
      </button>

      <button
        class="justify-end hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-500 rounded-full p-2"
        role="menu"
        aria-orientation="vertical"
        aria-labelledby="menu-button"
        tabindex="-1"
      ></button>

      <ng-container *ngIf="(accountService.currentUser$ | async) === null">
        <div class="dropdown" ngbDropdown>
          <a
            class="nav-link dropdown-toggle"
            id="navbarDropdown"
            role="button"
            ngbDropdownToggle
          >
            <i class="fa fa-user-circle-o fa-2x"></i>
          </a>
          <ul
            class="dropdown-menu dropdown-menu-end"
            ngbDropdownMenu
            aria-labelledby="navbarDropdown"
          >
            <li>
              <a class="dropdown-item" routerLink="/account/login"
                ><mat-icon>login</mat-icon> Iniciar sesión</a
              >
            </li>
            <li>
              <a class="dropdown-item" routerLink="/account/register/client"
                ><mat-icon>person_add</mat-icon> Registro Cliente</a
              >
            </li>
            <li>
              <a class="dropdown-item" routerLink="/account/register/provider"
                ><mat-icon>sell</mat-icon> Registro Vendedor</a
              >
            </li>
          </ul>
        </div>
      </ng-container>

      <ng-container *ngIf="accountService.currentUser$ | async as user">
        <div class="dropdown" ngbDropdown>
          <a
            class="nav-link dropdown-toggle"
            id="navbarDropdown"
            role="button"
            ngbDropdownToggle
          >
            <strong>{{ user.fullName }}</strong>
          </a>
          <ul
            class="dropdown-menu"
            ngbDropdownMenu
            aria-labelledby="navbarDropdown"
          >
            <li>
              <a routerLink="/account/profile" class="dropdown-item"
                ><i class="fa fa-user me-3"></i> Perfil</a
              >
            </li>
            <li>
              <a routerLink="/basket" class="dropdown-item"
                ><i class="fa fa-shopping-cart me-3"></i> Ver Cesta</a
              >
            </li>
            <li>
              <a routerLink="/orders" class="dropdown-item"
                ><i class="fa fa-history me-3"></i> Ver Pedidos</a
              >
            </li>
            <li>
              <hr class="dropdown-divider" />
            </li>
            <li>
              <a (click)="accountService.logout()" class="dropdown-item"
                ><i class="fa fa-sign-out me-3"></i> Cerrar Sesión</a
              >
            </li>
          </ul>
        </div>
      </ng-container>
    </div>
  </nav>
  <nav class="container mx-auto px-4 py-4 md:hidden sm:block">
    <div class="flex-grow">
      <form class="flex">
        <input
          class="focus:outline-none focus:ring-2 focus:ring-blue-500 w-full rounded-l-md border border-gray-300 px-4 py-2"
          type="search"
          (keyup.enter)="onSearch()"
          placeholder="Buscar productos..."
        />
        <button
          class="hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 rounded-r-md bg-blue-500 px-4 py-2 text-white"
          type="submit"
          (click)="onSearch()"
        >
          <i class="pi pi-search"></i>
        </button>
      </form>
    </div>
  </nav>
</header>-->

<header>
  <!-- navbar -->
  <div class="border-b">
    <div class="bg-gray-100 py-1">
      <div class="container">
        <div class="flex flex-wrap">
          <div class="md:w-1/2 w-full text-center md:text-left">
            <span>Super Value Deals - Save more with coupons</span>
          </div>
          <div class="w-1/2 text-right hidden lg:block">
            <div class="dropdown flex justify-end">
              <a
                class="dropdown-toggle text-inherit flex items-center"
                href="#!"
                data-bs-toggle="dropdown"
                id="dropdownMenuLink"
              >
                <span class="mr-2">
                  <svg
                    width="16"
                    height="13"
                    viewBox="0 0 16 13"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    id="languageIcon"
                  >
                    <!-- Default SVG content for English -->
                    <g clip-path="">
                      <path d="M0 0.5H16V12.5H0V0.5Z" fill="#012169" />
                      <path
                        d="M1.875 0.5L7.975 5.025L14.05 0.5H16V2.05L10 6.525L16 10.975V12.5H14L8 8.025L2.025 12.5H0V11L5.975 6.55L0 2.1V0.5H1.875Z"
                        fill="white"
                      />
                      <path
                        d="M10.6 7.525L16 11.5V12.5L9.225 7.525H10.6ZM6 8.025L6.15 8.9L1.35 12.5H0L6 8.025ZM16 0.5V0.575L9.775 5.275L9.825 4.175L14.75 0.5H16ZM0 0.5L5.975 4.9H4.475L0 1.55V0.5Z"
                        fill="#C8102E"
                      />
                      <path
                        d="M6.025 0.5V12.5H10.025V0.5H6.025ZM0 4.5V8.5H16V4.5H0Z"
                        fill="white"
                      />
                      <path
                        d="M0 5.325V7.725H16V5.325H0ZM6.825 0.5V12.5H9.225V0.5H6.825Z"
                        fill="#C8102E"
                      />
                    </g>
                  </svg>
                </span>
                <span id="selectedLanguage">English</span>
              </a>

              <ul class="dropdown-menu">
                <li>
                  <a
                    class="dropdown-item"
                    href="#!"
                    onclick="changeLanguage('English')"
                  >
                    <span>
                      <svg
                        width="16"
                        height="13"
                        viewBox="0 0 16 13"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clip-path="">
                          <path d="M0 0.5H16V12.5H0V0.5Z" fill="#012169" />
                          <path
                            d="M1.875 0.5L7.975 5.025L14.05 0.5H16V2.05L10 6.525L16 10.975V12.5H14L8 8.025L2.025 12.5H0V11L5.975 6.55L0 2.1V0.5H1.875Z"
                            fill="white"
                          />
                          <path
                            d="M10.6 7.525L16 11.5V12.5L9.225 7.525H10.6ZM6 8.025L6.15 8.9L1.35 12.5H0L6 8.025ZM16 0.5V0.575L9.775 5.275L9.825 4.175L14.75 0.5H16ZM0 0.5L5.975 4.9H4.475L0 1.55V0.5Z"
                            fill="#C8102E"
                          />
                          <path
                            d="M6.025 0.5V12.5H10.025V0.5H6.025ZM0 4.5V8.5H16V4.5H0Z"
                            fill="white"
                          />
                          <path
                            d="M0 5.325V7.725H16V5.325H0ZM6.825 0.5V12.5H9.225V0.5H6.825Z"
                            fill="#C8102E"
                          />
                        </g>
                        <defs>
                          <clipPath>
                            <rect
                              width="16"
                              height="12"
                              fill="white"
                              transform="translate(0 0.5)"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                    </span>
                    English
                  </a>
                </li>
                <li>
                  <a
                    class="dropdown-item"
                    href="#!"
                    onclick="changeLanguage('Deutsch')"
                  >
                    <span>
                      <svg
                        width="16"
                        height="13"
                        viewBox="0 0 16 13"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clip-path="url(#clip0_5543_19751)">
                          <path d="M0 8.5H16V12.5H0V8.5Z" fill="#FFCE00" />
                          <path d="M0 0.5H16V4.5H0V0.5Z" fill="black" />
                          <path d="M0 4.5H16V8.5H0V4.5Z" fill="#DD0000" />
                        </g>
                        <defs>
                          <clipPath id="clip0_5543_19751">
                            <rect
                              width="16"
                              height="12"
                              fill="white"
                              transform="translate(0 0.5)"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                    </span>
                    Deutsch
                  </a>
                </li>
                <!-- Add more languages as needed -->
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="pt-5">
      <div class="container">
        <div class="flex flex-wrap w-full items-center justify-between">
          <div class="lg:w-1/6 md:w-1/2 w-2/5">
            <a class="navbar-brand" href="/home">
              <img
                class="h-12"
                src="assets/images/logo.jpg"
                alt="TailwindCSS eCommerce HTML Template"
              />
            </a>
          </div>
          <div class="lg:w-2/5 hidden lg:block">
            <form action="#">
              <div class="relative">
                <label for="searchProducts" class="invisible hidden"
                  >Search</label
                >
                <input
                  class="border border-gray-300 text-gray-900 rounded-lg focus:shadow-[0_0_0_.25rem_rgba(10,173,10,.25)] focus:ring-orange-600 focus:ring-0 focus:border-orange-600 block p-2 px-3 disabled:opacity-50 disabled:pointer-events-none w-full text-base"
                  type="search"
                  placeholder="Search for products"
                  id="searchProducts"
                  (keyup.enter)="onSearch()"
                />
                <button
                  class="absolute right-0 top-0 p-3"
                  type="button"
                  (click)="onSearch()"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="icon icon-tabler icon-tabler-search"
                    width="16"
                    height="16"
                    viewBox="0 0 24 24"
                    stroke-width="2"
                    stroke="currentColor"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M10 10m-7 0a7 7 0 1 0 14 0a7 7 0 1 0 -14 0" />
                    <path d="M21 21l-6 -6" />
                  </svg>
                </button>
              </div>
            </form>
          </div>
          <div class="lg:w-1/5 hidden lg:block">
            <!-- Button trigger modal -->
            <button
              type="button"
              class="btn inline-flex items-center gap-x-2 bg-transparent text-gray-600 border-gray-300 disabled:opacity-50 disabled:pointer-events-none hover:text-white hover:bg-gray-700 hover:border-gray-700 active:bg-gray-700 active:border-gray-700 focus:outline-none focus:ring-4 focus:ring-gray-300"
              data-bs-toggle="modal"
              data-bs-target="#locationModal"
            >
              <span class="flex items-center gap-1">
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="icon icon-tabler icon-tabler-map-pin"
                    width="16"
                    height="16"
                    viewBox="0 0 24 24"
                    stroke-width="2"
                    stroke="currentColor"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M9 11a3 3 0 1 0 6 0a3 3 0 0 0 -6 0" />
                    <path
                      d="M17.657 16.657l-4.243 4.243a2 2 0 0 1 -2.827 0l-4.244 -4.243a8 8 0 1 1 11.314 0z"
                    />
                  </svg>
                </span>
                <span>Location</span>
              </span>
            </button>
          </div>
          <div class="lg:w-1/5 text-end md:w-1/2 w-3/5">
            <div class="flex gap-7 items-center justify-end">
              <div>
                <a href="shop-wishlist.html" class="relative">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="icon icon-tabler icon-tabler-heart"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    stroke-width="2"
                    stroke="currentColor"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path
                      d="M19.5 12.572l-7.5 7.428l-7.5 -7.428a5 5 0 1 1 7.5 -6.566a5 5 0 1 1 7.5 6.572"
                    />
                  </svg>
                  <span
                    class="absolute top-0 -mt-1 left-full rounded-full h-5 w-5 -ml-2 bg-orange-600 text-white text-center font-semibold text-sm"
                  >
                    5
                    <span class="invisible">unread messages</span>
                  </span>
                </a>
              </div>
              <div>
                <a
                  href="#!"
                  class="text-gray-600"
                  data-bs-toggle="modal"
                  data-bs-target="#userModal"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="icon icon-tabler icon-tabler-user"
                    width="22"
                    height="22"
                    viewBox="0 0 24 24"
                    stroke-width="2"
                    stroke="currentColor"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M8 7a4 4 0 1 0 8 0a4 4 0 0 0 -8 0" />
                    <path d="M6 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
                  </svg>
                </a>
              </div>
              <div>
                <button
                  type="button"
                  class="text-gray-600 relative"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvasRight"
                  role="button"
                  aria-controls="offcanvasRight"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="icon icon-tabler icon-tabler-shopping-bag"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    stroke-width="2"
                    stroke="currentColor"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path
                      d="M6.331 8h11.339a2 2 0 0 1 1.977 2.304l-1.255 8.152a3 3 0 0 1 -2.966 2.544h-6.852a3 3 0 0 1 -2.965 -2.544l-1.255 -8.152a2 2 0 0 1 1.977 -2.304z"
                    />
                    <path d="M9 11v-5a3 3 0 0 1 6 0v5" />
                  </svg>
                  <span
                    *ngIf="basketService.basketSource$ | async as basket"
                    routerLink="/basket"
                    id="cartCount"
                    class="absolute top-0 -mt-1 left-full rounded-full h-5 w-5 -ml-3 bg-orange-600 text-white text-center font-semibold text-sm"
                  >
                    {{ getCount(basket.items) }}
                    <span class="invisible">unread messages</span>
                  </span>
                </button>
              </div>
              <div class="lg:hidden leading-none">
                <!-- Button -->
                <button
                  class="collapsed"
                  type="button"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#navbar-default"
                  aria-controls="navbar-default"
                  aria-label="Toggle navigation"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="icon icon-tabler icon-tabler-menu-2 text-gray-800"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M4 6l16 0" />
                    <path d="M4 12l16 0" />
                    <path d="M4 18l16 0" />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <nav
      class="navbar relative navbar-expand-lg lg:flex lg:flex-wrap items-center content-between text-black navbar-default"
      aria-label="Offcanvas navbar large"
    >
      <div class="container max-w-7xl mx-auto w-full xl:px-4 lg:px-0">
        <div
          class="offcanvas offcanvas-left lg:visible"
          tabindex="-1"
          id="navbar-default"
        >
          <div class="offcanvas-header pb-1">
            <a href="index.html"
              ><img
                src="assets/images/logo/freshcart-logo.svg"
                alt="TailwindCSS eCommerce HTML Template"
            /></a>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="icon icon-tabler icon-tabler-x text-gray-700"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                stroke-width="2"
                stroke="currentColor"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M18 6l-12 12" />
                <path d="M6 6l12 12" />
              </svg>
            </button>
          </div>
          <div class="offcanvas-body lg:flex lg:items-center">
            <div class="block lg:hidden mb-4">
              <form action="#">
                <div class="relative">
                  <label for="searhNavbar" class="invisible hidden"
                    >Search</label
                  >
                  <input
                    class="border border-gray-300 text-gray-900 rounded-lg focus:shadow-[0_0_0_.25rem_rgba(10,173,10,.25)] focus:ring-orange-600 focus:ring-0 focus:border-orange-600 block p-2 px-3 disabled:opacity-50 disabled:pointer-events-none w-full text-base"
                    type="search"
                    placeholder="Search for products"
                    id="searhNavbar"
                  />
                  <button class="absolute right-0 top-0 p-3" type="button">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="icon icon-tabler icon-tabler-search"
                      width="16"
                      height="16"
                      viewBox="0 0 24 24"
                      stroke-width="2"
                      stroke="currentColor"
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <path d="M10 10m-7 0a7 7 0 1 0 14 0a7 7 0 1 0 -14 0" />
                      <path d="M21 21l-6 -6" />
                    </svg>
                  </button>
                </div>
              </form>
            </div>
            <div class="block lg:hidden mb-4">
              <a
                class="btn inline-flex items-center gap-x-2 bg-orange-600 text-white border-orange-600 disabled:opacity-50 disabled:pointer-events-none hover:text-white hover:bg-orange-700 hover:border-orange-700 active:bg-orange-700 active:border-orange-700 focus:outline-none focus:ring-4 focus:ring-orange-300 justify-center"
                data-bs-toggle="collapse"
                href="#collapseExample"
                role="button"
                aria-expanded="false"
                aria-controls="collapseExample"
              >
                <span class="mr-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="icon icon-tabler icon-tabler-layout-grid"
                    width="16"
                    height="16"
                    viewBox="0 0 24 24"
                    stroke-width="2"
                    stroke="currentColor"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path
                      d="M4 4m0 1a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v4a1 1 0 0 1 -1 1h-4a1 1 0 0 1 -1 -1z"
                    />
                    <path
                      d="M14 4m0 1a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v4a1 1 0 0 1 -1 1h-4a1 1 0 0 1 -1 -1z"
                    />
                    <path
                      d="M4 14m0 1a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v4a1 1 0 0 1 -1 1h-4a1 1 0 0 1 -1 -1z"
                    />
                    <path
                      d="M14 14m0 1a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v4a1 1 0 0 1 -1 1h-4a1 1 0 0 1 -1 -1z"
                    />
                  </svg>
                </span>
                All Departments
              </a>
              <div class="collapse mt-2" id="collapseExample">
                <div class="card card-body">
                  <ul class="list-unstyled">
                    <li>
                      <a class="dropdown-item" href="shop-grid.html"
                        >Dairy, Bread & Eggs</a
                      >
                    </li>
                    <li>
                      <a class="dropdown-item" href="shop-grid.html"
                        >Snacks & Munchies</a
                      >
                    </li>
                    <li>
                      <a class="dropdown-item" href="shop-grid.html"
                        >Fruits & Vegetables</a
                      >
                    </li>
                    <li>
                      <a class="dropdown-item" href="shop-grid.html"
                        >Cold Drinks & Juices</a
                      >
                    </li>
                    <li>
                      <a class="dropdown-item" href="shop-grid.html"
                        >Breakfast & Instant Food</a
                      >
                    </li>
                    <li>
                      <a class="dropdown-item" href="shop-grid.html"
                        >Bakery & Biscuits</a
                      >
                    </li>
                    <li>
                      <a class="dropdown-item" href="shop-grid.html"
                        >Chicken, Meat & Fish</a
                      >
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="dropdown hidden lg:block">
              <button
                class="mr-4 btn inline-flex items-center gap-x-2 bg-orange-600 text-white border-orange-600 disabled:opacity-50 disabled:pointer-events-none hover:text-white hover:bg-orange-700 hover:border-orange-700 active:bg-orange-700 active:border-orange-700 focus:outline-none focus:ring-4 focus:ring-orange-300"
                type="button"
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="icon icon-tabler icon-tabler-layout-grid"
                    width="16"
                    height="16"
                    viewBox="0 0 24 24"
                    stroke-width="2"
                    stroke="currentColor"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path
                      d="M4 4m0 1a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v4a1 1 0 0 1 -1 1h-4a1 1 0 0 1 -1 -1z"
                    />
                    <path
                      d="M14 4m0 1a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v4a1 1 0 0 1 -1 1h-4a1 1 0 0 1 -1 -1z"
                    />
                    <path
                      d="M4 14m0 1a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v4a1 1 0 0 1 -1 1h-4a1 1 0 0 1 -1 -1z"
                    />
                    <path
                      d="M14 14m0 1a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v4a1 1 0 0 1 -1 1h-4a1 1 0 0 1 -1 -1z"
                    />
                  </svg>
                </span>
                Todos las categorias
              </button>
              <ul class="dropdown-menu">
                <li *ngFor="let category of categories">
                  <a class="dropdown-item" href="shop-grid.html">{{
                    category.name
                  }}</a>
                </li>
              </ul>
            </div>
            <div>
              <ul class="navbar-nav lg:flex gap-3 lg:items-center">
                <li class="nav-item dropdown w-full lg:w-auto">
                  <a
                    class="nav-link dropdown-toggle"
                    href="#"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    >Home</a
                  >
                  <ul class="dropdown-menu">
                    <li>
                      <a class="dropdown-item" href="index.html"
                        >Home Default</a
                      >
                    </li>
                    <li>
                      <a class="dropdown-item" href="index-2.html">
                        Home Modern
                      </a>
                    </li>
                    <li>
                      <a class="dropdown-item" href="index-3.html">
                        Home Creative
                      </a>
                    </li>
                    <li>
                      <a class="dropdown-item" href="index-4.html">
                        Home Local Store
                      </a>
                    </li>
                    <li>
                      <a class="dropdown-item" href="index-5.html">
                        Home Minimalist
                      </a>
                    </li>
                  </ul>
                </li>
                <li class="nav-item dropdown w-full lg:w-auto">
                  <a
                    class="nav-link dropdown-toggle"
                    href="#"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    >Shop</a
                  >
                  <ul class="dropdown-menu">
                    <li>
                      <a class="dropdown-item" href="shop-grid.html"
                        >Shop Grid - Filter</a
                      >
                    </li>
                    <li>
                      <a class="dropdown-item" href="shop-grid-3-column.html"
                        >Shop Grid - 3 column</a
                      >
                    </li>
                    <li>
                      <a class="dropdown-item" href="shop-list.html"
                        >Shop List - Filter</a
                      >
                    </li>
                    <li>
                      <a class="dropdown-item" href="shop-filter.html"
                        >Shop - Filter</a
                      >
                    </li>
                    <li>
                      <a class="dropdown-item" href="shop-fullwidth.html"
                        >Shop Wide</a
                      >
                    </li>
                    <li>
                      <a class="dropdown-item" href="shop-single.html"
                        >Shop Single</a
                      >
                    </li>
                    <li>
                      <a class="dropdown-item" href="shop-wishlist.html"
                        >Shop Wishlist</a
                      >
                    </li>
                    <li>
                      <a class="dropdown-item" href="shop-cart.html"
                        >Shop Cart</a
                      >
                    </li>
                    <li>
                      <a class="dropdown-item" href="shop-checkout.html"
                        >Shop Checkout</a
                      >
                    </li>
                  </ul>
                </li>
                <li class="nav-item dropdown w-full lg:w-auto">
                  <a
                    class="nav-link dropdown-toggle"
                    href="#"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    >Stores</a
                  >
                  <ul class="dropdown-menu">
                    <li>
                      <a class="dropdown-item" href="store-list.html"
                        >Store List</a
                      >
                    </li>
                    <li>
                      <a class="dropdown-item" href="store-grid.html"
                        >Store Grid</a
                      >
                    </li>
                    <li>
                      <a class="dropdown-item" href="store-single.html"
                        >Store Single</a
                      >
                    </li>
                  </ul>
                </li>
                <li
                  class="nav-item dropdown w-full lg:w-auto dropdown-fullwidth"
                >
                  <a
                    class="nav-link dropdown-toggle"
                    href="#"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    >Mega menu</a
                  >
                  <div class="dropdown-menu pb-0">
                    <div
                      class="flex flex-wrap lg:flex-nowrap p-2 lg:p-6 gap-x-6"
                    >
                      <div class="lg:w-1/4 w-full mb-4">
                        <h6 class="text-orange-600 pl-3 mb-2">
                          Dairy, Bread & Eggs
                        </h6>
                        <a class="dropdown-item" href="shop-grid.html"
                          >Butter</a
                        >
                        <a class="dropdown-item" href="shop-grid.html"
                          >Milk Drinks</a
                        >
                        <a class="dropdown-item" href="shop-grid.html"
                          >Curd & Yogurt</a
                        >
                        <a class="dropdown-item" href="shop-grid.html">Eggs</a>
                        <a class="dropdown-item" href="shop-grid.html"
                          >Buns & Bakery</a
                        >
                        <a class="dropdown-item" href="shop-grid.html"
                          >Cheese</a
                        >
                        <a class="dropdown-item" href="shop-grid.html"
                          >Condensed Milk</a
                        >
                        <a class="dropdown-item" href="shop-grid.html"
                          >Dairy Products</a
                        >
                      </div>
                      <div class="lg:w-1/4 w-full mb-4">
                        <h6 class="text-orange-600 pl-3 mb-2">
                          Breakfast & Instant Food
                        </h6>
                        <a class="dropdown-item" href="shop-grid.html"
                          >Breakfast Cereal</a
                        >
                        <a class="dropdown-item" href="shop-grid.html"
                          >Noodles, Pasta & Soup</a
                        >
                        <a class="dropdown-item" href="shop-grid.html"
                          >Frozen Veg Snacks</a
                        >
                        <a class="dropdown-item" href="shop-grid.html"
                          >Frozen Non-Veg Snacks</a
                        >
                        <a class="dropdown-item" href="shop-grid.html"
                          >Vermicelli</a
                        >
                        <a class="dropdown-item" href="shop-grid.html"
                          >Instant Mixes</a
                        >
                        <a class="dropdown-item" href="shop-grid.html"
                          >Butter</a
                        >
                        <a class="dropdown-item" href="shop-grid.html"
                          >Fruit and Juices</a
                        >
                      </div>
                      <div class="lg:w-1/4 w-full mb-4">
                        <h6 class="text-orange-600 pl-3 mb-2">
                          Cold Drinks & Juices
                        </h6>
                        <a class="dropdown-item" href="shop-grid.html"
                          >Soft Drinks</a
                        >
                        <a class="dropdown-item" href="shop-grid.html"
                          >Fruit Juices</a
                        >
                        <a class="dropdown-item" href="shop-grid.html"
                          >Coldpress</a
                        >
                        <a class="dropdown-item" href="shop-grid.html"
                          >Water & Ice Cubes</a
                        >
                        <a class="dropdown-item" href="shop-grid.html"
                          >Soda & Mixers</a
                        >
                        <a class="dropdown-item" href="shop-grid.html"
                          >Health Drinks</a
                        >
                        <a class="dropdown-item" href="shop-grid.html"
                          >Herbal Drinks</a
                        >
                        <a class="dropdown-item" href="shop-grid.html"
                          >Milk Drinks</a
                        >
                      </div>
                      <div class="lg:w-1/4 w-full mb-4">
                        <div class="card border-0">
                          <img
                            src="assets/images/banner/menu-banner.jpg"
                            alt="TailwindCSS eCommerce HTML Template"
                          />
                          <div class="absolute pl-6 mt-8">
                            <h5>
                              Dont miss this
                              <br />
                              offer today.
                            </h5>
                            <a
                              href="#"
                              class="btn inline-flex items-center gap-x-2 bg-orange-600 text-white border-orange-600 disabled:opacity-50 disabled:pointer-events-none hover:text-white hover:bg-orange-700 hover:border-orange-700 active:bg-orange-700 active:border-orange-700 focus:outline-none focus:ring-4 focus:ring-orange-300 btn-sm mt-3"
                            >
                              Shop Now
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <li class="nav-item dropdown w-full lg:w-auto">
                  <a
                    class="nav-link dropdown-toggle"
                    href="#"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    >Pages</a
                  >
                  <ul class="dropdown-menu">
                    <li><a class="dropdown-item" href="blog.html">Blog</a></li>
                    <li>
                      <a class="dropdown-item" href="blog-single.html"
                        >Blog Single</a
                      >
                    </li>
                    <li>
                      <a class="dropdown-item" href="blog-category.html"
                        >Blog Category</a
                      >
                    </li>
                    <li>
                      <a class="dropdown-item" href="about.html">About us</a>
                    </li>
                    <li>
                      <a class="dropdown-item" href="404error.html"
                        >404 Error</a
                      >
                    </li>
                    <li>
                      <a class="dropdown-item" href="contact.html">Contact</a>
                    </li>
                  </ul>
                </li>
                <li class="nav-item dropdown w-full lg:w-auto">
                  <a
                    class="nav-link dropdown-toggle"
                    href="#"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    >Account</a
                  >
                  <ul class="dropdown-menu lg:right-0 xl:right-auto">
                    <li>
                      <a class="dropdown-item" href="signin.html">Sign in</a>
                    </li>
                    <li>
                      <a class="dropdown-item" href="signup.html">Signup</a>
                    </li>
                    <li>
                      <a class="dropdown-item" href="forgot-password.html"
                        >Forgot Password</a
                      >
                    </li>
                    <li class="dropdown-submenu dropend">
                      <a
                        class="dropdown-item dropdown-list-group-item dropdown-toggle"
                        href="#"
                        >My Account</a
                      >
                      <ul class="dropdown-menu">
                        <li>
                          <a class="dropdown-item" href="account-orders.html"
                            >Orders</a
                          >
                        </li>
                        <li>
                          <a class="dropdown-item" href="account-settings.html"
                            >Settings</a
                          >
                        </li>
                        <li>
                          <a class="dropdown-item" href="account-address.html"
                            >Address</a
                          >
                        </li>
                        <li>
                          <a
                            class="dropdown-item"
                            href="account-payment-method.html"
                            >Payment Method</a
                          >
                        </li>
                        <li>
                          <a
                            class="dropdown-item"
                            href="account-notification.html"
                            >Notification</a
                          >
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="dashboard/index.html">Dashboard</a>
                </li>

                <li class="nav-item dropdown w-full lg:w-auto dropdown-flyout">
                  <a
                    class="nav-link"
                    href="#"
                    id="navbarDropdownDocs"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    >Docs</a
                  >
                  <div
                    class="dropdown-menu dropdown-menu-lg lg:right-0 xl:right-auto"
                  >
                    <a class="dropdown-item items-start" href="docs/index.html">
                      <div>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="icon icon-tabler icon-tabler-file-text text-orange-500"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          stroke-width="2"
                          stroke="currentColor"
                          fill="none"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        >
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <path d="M14 3v4a1 1 0 0 0 1 1h4" />
                          <path
                            d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z"
                          />
                          <path d="M9 9l1 0" />
                          <path d="M9 13l6 0" />
                          <path d="M9 17l6 0" />
                        </svg>
                      </div>
                      <div class="ml-3 leading-none">
                        <h6 class="mb-1">Documentations</h6>
                        <p class="text-sm">Browse the all documentation</p>
                      </div>
                    </a>
                    <a
                      class="dropdown-item items-start"
                      href="docs/changelog.html"
                    >
                      <div>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="icon icon-tabler icon-tabler-box-multiple text-orange-500"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          stroke-width="2"
                          stroke="currentColor"
                          fill="none"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        >
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <path
                            d="M7 3m0 2a2 2 0 0 1 2 -2h10a2 2 0 0 1 2 2v10a2 2 0 0 1 -2 2h-10a2 2 0 0 1 -2 -2z"
                          />
                          <path
                            d="M17 17v2a2 2 0 0 1 -2 2h-10a2 2 0 0 1 -2 -2v-10a2 2 0 0 1 2 -2h2"
                          />
                        </svg>
                      </div>
                      <div class="ml-3 leading-none">
                        <h6 class="mb-1">
                          Changelog
                          <span class="text-orange-600 ml-1">v1.4.0</span>
                        </h6>
                        <p class="text-sm">See what's new</p>
                      </div>
                    </a>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </nav>
  </div>
</header>

<app-login-modal></app-login-modal>
