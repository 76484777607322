<main>
  <!-- section -->
  <section class="my-10">
    <!-- container -->
    <div class="container">
      <div class="flex flex-wrap justify-center">
        <div class="lg:mx-1/6 xl:w-4/5 w-full">
          <div class="flex gap-6 flex-wrap md:flex-nowrap items-center mb-8">
            <div class="md:w-1/2">
              <!-- text -->
              <div class="lg:mx-20 flex flex-col gap-4">
                <h1 class="text-2xl leading-tight">
                  El Futuro del Comercio B2B en México:
                </h1>
                <p class="text-md text-justify">
                  <strong>Viammex</strong> es la plataforma innovadora que
                  conecta exclusivamente a
                  <strong>proveedores y compradores mexicanos.</strong> Nuestro
                  objetivo es empoderar a los negocios locales, brindando acceso
                  a soluciones efectivas para el comercio y contribuyendo al
                  crecimiento económico de México. Al asociarnos con proveedores
                  confiables, damos a las empresas las herramientas necesarias
                  para impulsar sus ventas y ampliar su alcance, todo en un
                  entorno seguro y profesional.
                </p>
              </div>
            </div>

            <div class="md:w-1/2 w-full">
              <!-- img -->
              <img
                src="./assets/images/about/about-img.jpg"
                alt=""
                class="max-w-full w-full h-auto rounded-lg"
              />
            </div>
          </div>

          <div class="flex flex-wrap gap-6 mb-8">
            <div class="w-full">
              <!-- heading -->
              <h2 class="text-xl">¿Listo para empezar?</h2>
            </div>
            <div class="grid md:grid-cols-3 gap-6">
              <!-- card -->
              <div class="card bg-gray-100 border-0">
                <!-- card body -->
                <div class="card-body p-8 flex flex-col gap-5">
                  <div>
                    <!-- img -->
                    <img src="./assets/images/about/about-icons-1.svg" alt="" />
                  </div>
                  <div class="flex flex-col gap-2">
                    <h3 class="text-lg">Impulsa tu negocio con Viammex</h3>
                    <p class="text-justify">
                      Haz que tu negocio sea visible para miles de compradores.
                      Nuestra plataforma te permite subir tus productos,
                      gestionar pedidos y aumentar tus ventas con total
                      transparencia y sin complicaciones.
                    </p>
                  </div>
                  <!-- btn -->
                  <div>
                    <a
                      routerLink="/account/register/provider"
                      class="btn inline-flex items-center gap-x-2 bg-gray-800 text-white border-gray-800 disabled:opacity-50 disabled:pointer-events-none hover:text-white hover:bg-gray-700 hover:border-gray-700 active:bg-gray-700 active:border-gray-700 focus:outline-none focus:ring-4 focus:ring-gray-300"
                    >
                      Registro
                    </a>
                  </div>
                </div>
              </div>

              <!-- card -->
              <div class="card bg-gray-100 border-0">
                <!-- card body -->
                <div class="card-body p-8 flex flex-col gap-5">
                  <div>
                    <!-- img -->
                    <img src="./assets/images/about/about-icons-2.svg" alt="" />
                  </div>
                  <div class="flex flex-col gap-2">
                    <h3 class="text-lg">Ventajas de comprar en Viammex</h3>
                    <p class="text-justify">
                      Accede a proveedores 100% mexicanos y productos de alta
                      calidad. En Viammex, conectamos a compradores con
                      vendedores comprometidos con la autenticidad y excelencia
                      del comercio nacional. Nuestra plataforma te ofrece:
                    </p>
                    <!-- btn -->
                    <li>
                      Productos garantizados directamente por proveedores
                      confiables.
                    </li>
                    <li>
                      Amplia variedad de opciones para satisfacer las
                      necesidades de tu negocio.
                    </li>
                    <li>
                      Soporte en cada etapa del proceso de compra para asegurar
                      tu satisfacción.
                    </li>
                  </div>
                  <div>
                    <a
                      routerLink="/conocenos"
                      class="btn inline-flex items-center gap-x-2 bg-gray-800 text-white border-gray-800 disabled:opacity-50 disabled:pointer-events-none hover:text-white hover:bg-gray-700 hover:border-gray-700 active:bg-gray-700 active:border-gray-700 focus:outline-none focus:ring-4 focus:ring-gray-300"
                    >
                      Sobre nosotros
                    </a>
                  </div>
                </div>
              </div>

              <!-- card -->
              <div class="card bg-gray-100 border-0">
                <!-- card body -->
                <div class="card-body p-8 flex flex-col gap-5">
                  <div>
                    <!-- img -->
                    <img src="./assets/images/about/about-icons-3.svg" alt="" />
                  </div>
                  <div class="flex flex-col gap-2">
                    <h3 class="text-lg">Aprende más sobre Viammex</h3>
                    <p class="text-justify">
                      En <strong>Viammex</strong>, no solo te ofrecemos una
                      plataforma, sino un acompañamiento completo. Nuestro
                      compromiso es estar contigo en cada paso del camino,
                      ayudándote a resolver problemas, optimizar tus operaciones
                      y lograr el éxito en tu negocio.
                    </p>
                  </div>
                  <!-- btn -->
                  <div>
                    <a
                      routerLink="/atencion-a-clientes"
                      class="btn inline-flex items-center gap-x-2 bg-gray-800 text-white border-gray-800 disabled:opacity-50 disabled:pointer-events-none hover:text-white hover:bg-gray-700 hover:border-gray-700 active:bg-gray-700 active:border-gray-700 focus:outline-none focus:ring-4 focus:ring-gray-300"
                    >
                      Atención al cliente
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</main>
