import { SwiperConfig, SwiperConfigParsed } from '../types/swiper.types';

export function parseBreakpoints(
  breakpointsData: string | undefined | null,
): Record<string, any> {
  if (!breakpointsData) return {};

  try {
    return JSON.parse(breakpointsData);
  } catch (error) {
    console.error('Error parsing breakpoints data:', error);
    return {};
  }
}

export function parseSwiperConfig(element: HTMLElement): SwiperConfigParsed {
  const config: SwiperConfig = {
    speed: element.getAttribute('data-speed') ?? '400',
    spaceBetween: element.getAttribute('data-space-between') ?? '100',
    pagination: element.getAttribute('data-pagination') ?? 'false',
    navigation: element.getAttribute('data-navigation') ?? 'false',
    autoplay: element.getAttribute('data-autoplay') ?? 'false',
    autoplayDelay: element.getAttribute('data-autoplay-delay') ?? '3000',
    paginationType: element.getAttribute('data-pagination-type') ?? 'bullets',
    effect: element.getAttribute('data-effect') ?? 'slide',
    breakpoints: element.getAttribute('data-breakpoints'),
  };

  return {
    speed: parseInt(config.speed),
    spaceBetween: parseInt(config.spaceBetween),
    paginationEnabled: config.pagination === 'true',
    navigationEnabled: config.navigation === 'true',
    autoplayEnabled: config.autoplay === 'true',
    autoplayDelay: parseInt(config.autoplayDelay),
    paginationType: config.paginationType,
    effect: config.effect,
    breakpoints: parseBreakpoints(config.breakpoints),
  };
}
